// COMPONENT.BOOKING-PANEL

.c-booking-panel {
	margin: $lsu 0;
}

.c-booking-panel__body {
	@include flex-extend;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	@include bp("medium") {
		flex-direction: row;
	}

	box-shadow: 0 3px 10px 2px rgba($black, 0.2);
	padding: $bsu;
	background: $white;
}

.c-booking-panel__details {}

.c-booking-panel__details-primary {
	@include flex-extend;
	flex-wrap: wrap;
	@include bp("large") {
		flex-wrap: nowrap;
	}
}

.c-booking-panel__details-secondary {
	@include font-body-text;
	margin-top: $bsu;

	:last-child {
		margin-bottom: 0;
	}
}

.c-booking-panel__details-list {
	@include flex-extend;
	flex-grow: 1;

	width: 100%;
	margin-left: -$bsu;
	@include bp("medium") {
		margin-left: -$lsu;
	}
	@include bp("large") {
		width: auto;
	}

	+ .c-booking-panel__details-list {
		margin-top: $bsu;
		@include bp("large") {
			margin-top: 0;
			margin-left: 0;
		}
	}
}

.c-booking-panel__details-item {
	padding-left: $bsu;
	@include bp("medium") {
		padding-left: $lsu;
	}
}

.c-booking-panel__details-item-title {
	display: block;
	margin-bottom: $tsu;

	@include font-base-14;
}

.c-booking-panel__details-item-value {
	@include font-heading-24;
}

.c-booking-panel__details-item-note {
	display: block;
	margin-top: $ssu;
	@include font-base-12;
}

.c-booking-panel__action {
	margin-top: $bsu;
	@include bp("medium") {
		margin-top: 0;
		margin-left: $lsu;
	}
}
