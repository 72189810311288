// COMPONENT.EXPERIENCE-TILE

.c-experience-tile__wrapper {
	height: 100%;
	padding-bottom: 27px;

}

.c-experience-tile {
	@include flex-extend;
	height: 100%;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	border-radius: 2px;
	box-shadow: 0 3px 10px 2px rgba($black, 0.2);
	transition: border 0.2s ease-in-out;
	border: 1px transparent solid;

	&:hover {
		border-color: $brand;
	}

}

.c-experience-tile__figure {
	overflow: hidden;
	border-radius: 2px 2px 0 0;
}

.c-experience-tile__highlight {
	position: absolute;
	top: 0;
	left: 0;

	@include font-base-10sb;
	border-radius: 2px 0 0 0;
	padding: $tsu $ssu $tsu $ssu;
	transition: top 0.2s ease-in-out;

	&:before {
		content: '';
		display: block;
		width: 12px;
		transform: skewX(-30deg);
		background: inherit;
		position: absolute;
		height: 100%;
		top: 0;
		right: -6px;
	}
}

// When there is no figure
.c-experience-tile__highlight:first-child + .c-experience-tile__body {
	margin-top: $bsu;
}

.c-experience-tile__body {
	flex: 1 0 auto;
	padding: $bsu;
	@include bp("medium") {
		padding: $lsu $lsu $bsu;
	}
}

.c-experience-tile__subheading {
	display: block;
	margin-bottom: $ssu;

	@include font-base-12;
	color: $body-color;
	word-wrap: break-word;
}

.c-experience-tile__heading {
	display: block;
	margin-bottom: $bsu;

	@include font-heading-20;
	@include bp('large') {
		@include font-heading-24;
	}
	@include link-transition;

	@supports (color: var(--primary-a)) {
		position: relative;
		padding-bottom: $bsu;
		&::after {
			content: "";
			position: absolute;
			left: 0;
			bottom: -2px;
			width: 80px;

			border-bottom: 4px solid var(--primary-a);
		}
	}
}

.c-experience-tile__text {
	display: block;

	@include font-body-text;
	color: $body-color;

	> :last-child {
		margin-bottom: 0;
	}
}

.c-experience-tile__actions {
	@include flex-extend;
	@include flex-wrap;
	justify-content: center;

	padding: 0 $bsu;
	margin-bottom: -27px;
}

.c-experience-tile__btn {
	flex: 1 0 auto;
}