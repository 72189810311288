﻿// CONFIG

// Layout
// Max width of layout wrapper which contains content
// Width of sidebar (default is to float at 'large' breakpoint)
$viewport-max-width: 1920px;

$layout-wrapper-max-width: 1360px;
$layout-aside-width: 280px;
$layout-aside-gutter-width: 60px;
$layout-wrapper-narrow-width: $layout-wrapper-max-width - ($layout-aside-width + $layout-aside-gutter-width);
$layout-wrapper-modal-width: $layout-wrapper-narrow-width;

// Spacings
// $bsu: Base spacing unit
// $tsu: Tiny spacing unit
// $ssu: Small spacing unit
// $lsu: Large spacing unit
// $hsu: Huge spacing unit
$bsu: 20px;
$tsu: calc($bsu / 4);
$ssu: calc($bsu / 2);
$lsu: calc($bsu * 2);
$hsu: calc($bsu * 4);

// Breakpoints
// Suffixes used for namespaced helpers
// Pixel values will be output as em units
$breakpoints: (
	"extra-small" (
		"suffix": "xs",
		"start": 0,
		"end": 479px
	),
	"small" (
		"suffix": "s",
		"start": 480px,
		"end": 767px
	),
	"medium" (
		"suffix": "m",
		"start": 768px,
		"end": 1023px
	),
	"large" (
		"suffix": "l",
		"start": 1024px,
		"end": 1279px
	),
	"extra-large" (
		"suffix": "xl",
		"start": 1280px,
		"end": 9000px
	)
);

// Columns
// By default we will create wholes, halves, thirds, quarters, and fifths
// Predefine this map to override
$columns: (1, 2, 3, 4, 5);

// Z-Index
// 1
$z-page: 1;

// 2
$z-header: 2;
$z-share: 2;
$z-slick-arrows: 2;

// 3
$z-tooltip: 3;

// 4
$z-back-to-top : 4;