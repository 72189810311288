﻿// COMPONENT.BREADCRUMB

.c-breadcrumb {
	margin-top: $lsu;
	margin-bottom: $ssu;
}

.c-breadcrumb__link {
	@include font-base-12;
}

.c-breadcrumb__current {
	@include link-plain;
	@include font-base-12b;
}