// COMPONENT.CTA-INTRO

.c-cta-intro {
	margin-bottom: $hsu;
}

.c-cta-intro__layout {
	@include bp("medium") {
		@include flex-extend;
	}
}

.c-cta-intro__body {
	@include bp("medium") {
		padding-right: $lsu;
	}

	.c-accommodation__content-wrapper & {
		margin: ($bsu * 3) 0;

		@include bp("medium") {
			padding-right: 0;
		}
	}
}

.c-cta-intro__cta {
	@include flex-extend;
	flex-direction: column;
	align-items: flex-end;

	margin: 0 auto;
	max-width: 320px;
	@include bp("medium") {
		flex-shrink: 0;
		width: 320px;
	}
}

.c-cta-intro__cta-body {
	width: 100%;
	@include flex-extend;
}

.c-cta-intro__cta-figure {
	flex: 1 0 auto;
	min-width: 100px;
	max-width: 200px;

	.c-cta-intro__cta-image {
		overflow: hidden;
	}
}

.c-cta-intro__cta-image {
	position: relative;
	top: $lsu;
}

.c-cta-intro__cta-text {
	@include flex-extend;
	flex-direction: column;
	justify-content: flex-end;

	padding-left: $ssu;
}

.c-cta-intro__cta-subheading {
	display: block;
	margin-bottom: $tsu;

	@include font-heading-13;
	color: $body-color;
}

.c-cta-intro__cta-heading {
	display: block;

	@include font-heading-28;
	@include link-transition;

	&::after {
		@include font-icon;
		content: $iconf-arrow-right;

		display: inline-block;
		font-size: 0.5em;
		vertical-align: middle;
		margin-left: $ssu;
	}
}

.c-cta-intro__cta-button {
	display: block;
	margin-top: $lsu;
}
