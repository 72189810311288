﻿// CONTROL.IMAGE-CTA

.c-image-cta__viewport {
	height: 0;
	padding-bottom: 100%; //mobile use square crop for now
	overflow: hidden;

	@include bp('large') {
		padding-bottom: percentage(calc(384/1366)); //desktop use hero crop for now
	}
}

.c-image-cta__item {
	width: 100%;
	position: relative;
}

.c-image-cta__body {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	background: rgba($black, 0.2);
	color: $white;
}

.c-image-cta__body-content {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
}

.c-image-cta__abstract {
	margin-top: $bsu;
	@include font-base-14;
}

.c-image-cta__btn {
	margin-top: $bsu;
}