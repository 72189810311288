// COMPONENT.COOKIE-CONFIRM

.c-cookie-confirm {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	display: none;

	background: $white;
	box-shadow: 0 -6px 6px -6px rgba($black, 0.2);
}

.c-cookie-confirm__body {
	padding: $tsu 0;

	@include bp('medium') {
		display: flex;
		align-items: center;
	}

	@include bp("large") {
		position: relative;
		padding-right: $lsu;
	}
}

.c-cookie-confirm__message {
	margin-bottom: $ssu;

	@include bp('medium') {
		flex: 1 1 auto;
		margin: 0;
	}
}

.c-cookie-confirm__button {
	flex: 0 1 auto;

	@include bp('medium') {
		margin-left: $ssu;
	}
}
