// OBJECT.BTN

// Shared button styles

.o-btn,
.umbraco-forms-form .btn {
	@include font-base-16;
}


///////////
// SIZES //
///////////
.o-btn--large {
	@include font-base-18;
	padding: 24px $lsu;
}

.o-btn--medium {
	// This is the default size, all inherited from .o-btn
}

.o-btn--small {
	@include font-base-14;
	padding: 11px 17px;
}

.o-btn--smallest {
	@include font-base-12;
	padding: 8px 12px;
}

.o-btn--text {
	@include font-base-14;
}
