// COMPONENT.NAVIGATION

$megamenu-bp: "large";


// Main Navigation
$navigation-bg: $white;


.c-navigation__link {
	@include font-base-14b;

	@supports (color: var(--secondary-a)) {
		color: var(--secondary-a)
	}
}


//////////////
// Megamenu //
//////////////
.c-navigation__megamenu {
	top: 98%;
}

.c-navigation__megamenu-abstract-heading {
	@include font-base-24b;
}

.c-navigation__megamenu-abstract-text {
	@include font-body-text;
}

.c-navigation__megamenu-abstract-link {
	@include font-base-14b;

	.o-btn__text {
		@include font-base-14b;
	}
}

.c-navigation__megamenu-section-link {
	@include font-base-16b;

	&.t-link {
		@supports (color: var(--link--hover)) {
			color: var(--link--hover)
		}

		&:hover {
			@supports (color: var(--link)) {
				color: var(--link)
			}
		}
	}
}


