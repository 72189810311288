// COMPONENT.HEADER

$header-height: 70px;

.c-header {
	z-index: $z-header;
	height: $header-height;
}

.c-header__spacer {
	height: $header-height;
}


.c-header__logo {

	@include bp("extra-large") {
		padding: ($ssu * 1.5) $bsu;
	}

	img {
		width: 150px;

		@include bp("extra-large") {
			width: 200px;
		}
	}
}

.c-header__tools {
	@include flex-extend;
	align-items: center;
	justify-content: flex-end;
	order: 3;
	padding-right: $ssu;
	@include bp("extra-large") {
		padding-right: $bsu;
	}
}

.c-header__tool {
	display: block;
	@include bp("small") {
		margin-right: $ssu;
	}
}

.c-header__tool--button {
	@include flex-extend;

	font-size: 16px;
	padding: calc((44px - 16px)/2);

	+ .c-header__tool--button {
		margin-left: -$ssu;
	}
}

.c-header__tool--largest-desktop {
	@include bp-below(1440px) {
		display: none;
	}
}
.c-header__tool--largest-mobile {
	@include bp(1440px) {
		display: none;
	}
}

.c-header__tool-inner-button {
	@include button-reset;

	position: relative;
	&::before {
		content: "";
		@include abs-center;
		min-height: 100%;
		height: 44px;
		min-width: 100%;
		width: 44px;
	}
}

.c-header__tool-reveal {
	display: block;
	margin-left: 0;
	visibility: hidden;

	white-space: nowrap;

	@include font-base-14;

	width: 0;
	overflow: hidden;
	@include link-transition;

	&[aria-hidden="false"] {
		margin-left: $bsu;
		width: 140px;
	}

	&.allow-animation {
		&[aria-hidden="false"] {
			animation: 0.5s becomeVisible forwards,
			           0.5s fadein forwards;
		}
		&[aria-hidden="true"] {
			animation: 0.5s becomeHidden forwards,
			           0.5s fadeout forwards;
		}
	}
}

// Language
.c-header__language {
	display: none;

	@include bp('medium') {
		display: block;
		padding-right: $bsu;
	}
}

.c-header__language-dropdown {
	@include font-16;
	display: block;
	padding: 0.65em;
	border: 1px solid $grey;
}


// Mobile buttons
$mobile-button-size: 45px;
$mobile-button-color: $brand;
$mobile-button--hover-color: darken($brand, 15);
$mobile-button-font-size: 20px;
$mobile-button-padding: calc(($mobile-button-size - $mobile-button-font-size) / 2);

.c-header__mobile-buttons {
	flex: 1 0 auto;
	text-align: right;

	@include bp('medium') {
		flex: 0 0 auto;
	}
}

.c-header__mobile-button {
	position: relative;
	width: $mobile-button-size;
	height: $mobile-button-size;
	padding: $mobile-button-padding 0;
	border: 0;
	background: none;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}

// Nav Toggle
.c-header__nav-toggle {
	b {
		transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
		position: absolute;
		left: $mobile-button-padding;
		display: block;
		width: $mobile-button-font-size;
		height: 1px;

		&:nth-child(1) {
			top: $mobile-button-padding;
		}

		&:nth-child(2) {
			top: 50%;
		}

		&:nth-child(3) {
			top: calc(100% - #{$mobile-button-padding});
		}
	}

	&[aria-pressed="true"] {
		b {
			&:nth-child(1) {
				transform: translateY(#{calc($mobile-button-font-size / 2)}) rotate(45deg);
			}

			&:nth-child(2) {
				opacity: 0;
			}

			&:nth-child(3) {
				transform: translateY(-#{calc($mobile-button-font-size / 2)}) rotate(-45deg);
			}
		}
	}
}
