﻿// CONTROL.TRANSFER-WIDGET

.c-transfer-widget-embed {
	
	@include bp("medium") {
		margin-bottom: ($bsu*3);
	}
	
}

.c-transfer-widget {
	display: flex;
	flex-direction: column;
	position: relative;

	@include bp("medium") {
		flex-direction: column-reverse;
	}

	@include bp("extra-large") {
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
	}
}

.c-transfer-widget__content-wrapper {
	padding: $lsu;
	z-index: 1;
	width: 100%;

	@include bp("medium") {
		width: 90%;
		position: relative;
		left: 0;
	}

	@include bp("extra-large") {
		width: 60%;
		position: absolute;
	}
}

.c-transfer-widget__image-wrapper {
	display: none;

	@include bp("medium") {
		display: block;
		width: 90%;
		align-self: flex-end;
		margin-bottom: -160px;
	}

	@include bp("extra-large") {
		width: 60%;
		margin-bottom: 0;
	}
}

.c-transfer-widget__heading {
	color: $white;
	padding-bottom: 0;
	margin-bottom: $bsu;

	&::after {
		display: none;
	}
}

.c-transfer-widget__top-content {
	color: $white;
	text-align: center;
}

.c-form__submit-wrapper {
	text-align: center;

	@include bp("medium") {
		text-align: right;
	}

	.c-form__submit-btn {
		width: 100%;

		@include bp("medium") {
			width: auto;
		}
	}
}

.c-transfer-widget__bottom-content {
	@include font-heading-18;
	color: $white;
	margin-top: $bsu;

	@include bp("medium") {
		margin-top: -$bsu;
	}

	a {
		color: $white;

		&:hover {
			text-decoration: underline;
		}
	}
}

.c-image-feature {
	+ .c-image-feature {
		margin-top: -($lsu + 1px);

		@include bp("medium") {
			margin-top: -121px;
		}

		.c-image-feature__layout {
			border-top: none;
		}
	}

	&.o-theme-dark {
		+ .c-image-feature {
			margin-top: -$lsu;

			@include bp("medium") {
				margin-top: -120px;
			}
		}
	}
}

.c-image-feature__layout {
	background-color: $white;
	border: 1px solid $grey;

	@include bp('medium') {
		@include flex-extend;
		padding: 0;
	}
}

.c-image-feature__layout--right {
	@include bp('medium') {
		flex-flow: row-reverse;
	}
}

.c-image-feature__figure {
	@include bp('medium') {
		flex: 0 0 50%;
		align-self: stretch;
		margin-bottom: 0;
	}
}

.c-image-feature__figure--secondary {
	display: none;
}

@include bp("medium") {
	@include bp-below("large") {
		.c-image-feature__figure--primary {
			display: none;
		}

		.c-image-feature__figure--secondary {
			display: block;
		}
	}
}

@include bp("large") {
	.o-layout-content--has-aside {
		.c-image-feature__figure--primary {
			display: none;
		}

		.c-image-feature__figure--secondary {
			display: block;
		}
	}
}

.c-transfer-widget-from__selectbox {
	option[data-show-option="false"] {
		display: none;
	}

	option[data-show-option="true"] {
		display: block;
	}
}
