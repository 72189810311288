﻿// OBJECT.POD

.o-pod {
	position: relative;
	display: block;
	height: 400px;
}

.o-pod__flipper {
	perspective: 1000px;

	&:hover,
	&:focus {
		.o-pod__body {
			.o-pod__heading {
				opacity: 0;
			}
		}

		.o-pod__flip {
			opacity: 0.9;
			transform: rotateY(0);
		}
	}
}

.o-pod__body {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	padding: 0.666em;
	text-align: left;
	color: $white;
}

.o-pod__heading {
	@include font-heading-20;
	position: absolute;
	left: 0;
	bottom: 0;
	padding: $bsu;

	@include link-transition(opacity);
}

.o-pod__flip {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 60px $bsu 70px;
	opacity: 0;
	transform: rotateY(-90deg);
	transition: 0.5s;
	transform-style: preserve-3d;

	.o-pod__heading {
		display: block;
		position: static;
		padding: 0;
		margin-bottom: $bsu;
	}

	.o-pod__text {
		@include font-body-text;
	}
}

.o-pod__flip-icon {
	position: absolute;
	right: 30px;
	bottom: 30px;
	font-size: 32px;
}
