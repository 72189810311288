// COMPONENT.HERO-HOME

$hero-home-bp: "medium";

.c-hero-home {
	position: relative;

	@include bp($hero-home-bp) {
		margin-bottom: 300px;
	}
	@include bp("large") {
		margin-bottom: 200px;
	}

	.c-info-widget__wrapper {
		transform: translateY(-$lsu);
		@include bp("medium") {
			transform: translateY(-$hsu);
		}
		@include bp("large") {
			transform: translateY(-100%);
		}
	}
}

.c-hero-home__primary {
	position: relative;
	@include bp($hero-home-bp) {
		min-height: 450px;
		height: 80vh;
		overflow: hidden;
	}
	@include bp("large") {
		min-height: 600px;
	}
}

.c-hero-home__video {
	display: none;
	@include bp($hero-home-bp) {
		display: block;
	}

	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
	height: 100%;

	object-fit: cover;
	background-size: cover;
}

.c-hero-home__image {
	display: block;
	@include bp($hero-home-bp) {
		display: none;
	}

	width: 100%;
}

.c-hero-home__body {
	position: absolute;
	bottom: $hsu;
	@include bp($hero-home-bp) {
		bottom: 125px;
	}
	max-width: 670px;
	@include bp(1074px) {
		max-width: 720px;
	}
}

.c-hero-home__quote {
	display: block;
	margin-bottom: $bsu;
}

.c-hero-home__heading {
	display: block;
	@include font-heading-36;
	@include bp("medium") {
		@include font-heading-50;
	}
	@include bp("large") {
		@include font-heading-72;
	}

	color: $white;
}

.c-hero-home__foot {
	@include bp($hero-home-bp) {
		position: absolute;
		left: 0;
		top: calc(100% - 125px);
		padding-top: 120px;

		width: 100%;
		height: 495px;

		background: url(/content/images/interface/bg/bg-hero-map-1024.png) top center no-repeat;
		@include bp(1024px) {
			background-image: url(/content/images/interface/bg/bg-hero-map-1440.png);
		}
		@include bp(1440px) {
			background-image: url(/content/images/interface/bg/bg-hero-map-1920.png);
		}
	}
}

.c-hero-home__promo-wrapper {
	@include flex-extend;

	@include bp("medium") {
		@include bp-below(900px) {
			display: none;
		}
	}
}

.c-hero-home__promo {
	@include flex-extend;
	align-items: stretch;
}

.c-hero-home__promo-image-wrapper {
	width: 125px;
}

.c-hero-home__promo-image {
	display: block;
	background-color: transparent !important;
}

.c-hero-home__promo-body {
	@include flex-extend;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;

	margin-left: $bsu;
	max-width: 200px;
	padding-bottom: $ssu;
}

.c-hero-home__promo-subheading {
	@include font-heading-13;

	color: $body-color;
}

.c-hero-home__promo-heading {
	@include font-heading-20;
	@include bp("small") {
		@include font-heading-28;
	}
	@include link-transition;

	&::after {
		@include font-icon;
		content: $iconf-arrow-right;

		font-size: 0.8em;
		margin-left: $ssu;
	}
}

.c-hero-home__main {
	position: relative;
	// Make sure controls after the hero aren't pushed behind it
}