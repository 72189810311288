// COMPONENT.EXPERIENCE-HERO

$experience-hero-bp: "large";

.c-experience-hero {
	@include flex-extend;
	align-items: stretch;
	position: relative;
	z-index: 1;
	min-height: 200px;

	@include bp("medium") {
		height: 50vh;
		min-height: 400px;
	}

	max-height: 80vh;

	&::before {
		content: "";
		position: absolute;
		z-index: 1;
		bottom: 0;
		left: 0;
		right: 0;
		padding-top: percentage(calc(139 / 1600));
		background: url(/content/images/interface/bg/bg-hero.svg) no-repeat top center;
		background-size: cover;
	}

	&::after {
		content: "";
		position: absolute;
		top: 100%;
		margin-top: -1px;
		left: 0;
		right: 0;
		height: 200px;
		background: linear-gradient(to bottom, $blue--light 0%, $blue--light 1px, rgba($blue--light, 0) 100%);
	}
}

.c-experience-hero__image {
	overflow: hidden;
	@include bp($experience-hero-bp) {
		width: 100%;
		height: 100%;

		padding-bottom: 0 !important;
		height: 100%;
	}
}

.c-experience-hero__actions {
	@include list-reset;
	position: absolute;
	bottom: $lsu;
	z-index: 1;
	left: $bsu;

	@include bp($layout-wrapper-max-width) {
		left: calc(50% - #{calc($layout-wrapper-max-width / 2) - $bsu});
	}

	margin-left: -$bsu;

	@include flex-extend;
}

.c-experience-hero__action {
	padding-left: $bsu;
	&:last-child {
		padding-right: $bsu;
	}
}

.c-experience-hero__action-button {
	opacity: 0.9;

	&:hover,
	&:focus {
		opacity: 1;
	}
}

@include bp-below("medium") {
	.c-experience-hero--empty + .c-experience-hero__foot {
		.c-info-widget__wrapper {
			margin-top: -$hsu;
		}
	}
}

.c-experience-hero__foot {
	position: relative;
	.c-info-widget__wrapper {
		bottom: 0;
	}
}
