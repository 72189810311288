﻿// CONTROL.HIGHLIGHTS

.c-highlights__pod {
	position: relative;
	height: 100%;
	background-color: $grey--lightest;

	@include bp('medium') {
		display: flex;
	}
}

.c-highlights__content {
	padding: $bsu;

	@include bp('medium') {
		flex: 0 0 50%;
		padding: ($bsu * 2) $bsu;
	}

	.no-image & {
		padding-top: $lsu;
	}
}

.c-highlights__figure {

	@include bp('medium') {
		flex: 0 0 50%;
		align-self: stretch;
	}
}

.c-highlights__image {

	@include bp('medium') {
		height: 100%;
	}
}

.c-highlights__title {
	@include font-base-20b;
	margin-bottom: 0.5em;
	color: $brand;

	@include bp('medium') {
		margin-bottom: 1.3em;
	}
}

.c-highlights__abstract {
	@include font-body-text;
	margin-bottom: 1.5em;

	@include bp('medium') {
		margin-bottom: 3em;
	}

	@include bp('large') {
		margin-bottom: 2em;
	}
}

.c-highlights__link {
	@include font-base-16sb;
}

.c-highlights__link-icon {
	position: relative;
	top: 2px;
}

.c-highlights__tag {
	@include font-base-10sb;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;
	padding: $tsu $ssu;
	color: $white;
	background: $brand;
}
