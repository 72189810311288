﻿// CONTROL.IMAGE-FEATURE

.c-image-feature {
	+ .c-image-feature {
		margin-top: -($lsu + 1px);
		@include bp("medium") {
			margin-top: -121px;
		}
		.c-image-feature__layout {
			border-top: none;
		}
	}

	&.o-theme-dark {
		+ .c-image-feature {
			margin-top: -$lsu;
			@include bp("medium") {
				margin-top: -120px;
			}
		}
	}
}

.c-image-feature__layout {
	background-color: $white;
	border: 1px solid $grey;

	@include bp('medium') {
		@include flex-extend;
		padding: 0;
	}
}

.c-image-feature__layout--right {
	@include bp('medium') {
		flex-flow: row-reverse;
	}
}

.c-image-feature__figure {
	@include bp('medium') {
		flex: 0 0 50%;
		align-self: stretch;
		margin-bottom: 0;
	}
}

.c-image-feature__figure--secondary {
	display: none;
}

@include bp("medium") {
	@include bp-below("large") {
		.c-image-feature__figure--primary {
			display: none;
		}

		.c-image-feature__figure--secondary {
			display: block;
		}
	}
}

@include bp("large") {
	.o-layout-content--has-aside {
		.c-image-feature__figure--primary {
			display: none;
		}

		.c-image-feature__figure--secondary {
			display: block;
		}
	}
}

.c-image-feature__image {
	@include bp('medium') {
		height: 100%;
	}
}

.c-image-feature__body {
	padding: $bsu;
	width: 100%;

	@include bp('medium') {
		align-self: center;
		padding: ($bsu * 3) ($bsu * 2);
	}

	@include bp('large') {
		padding: $bsu * 4;

		.o-layout-content--has-aside & {
			padding: ($bsu * 3) ($bsu * 2);
		}
	}

	> *:last-child,
	&:last-child {
		margin-bottom: 0;
	}
}

.c-image-feature__subheading {
	@include font-base-14b;
	display: block;
	margin-bottom: $bsu;
}

.c-image-feature__heading {
	@include font-heading-24;
	position: relative;
	margin-bottom: 0.5em;
	color: $brand;

	@include bp('large') {
		@include font-heading-28;
		padding-bottom: 0.95em;
		margin-bottom: 1.35em;

		&::before {
			@include pseudo-element;
			left: 0;
			bottom: 0;
			width: 100px;
			height: 5px;
			background-color: $brand;
		}
	}
}

.c-image-feature__text {
	margin-bottom: $bsu;

	@include bp('large') {
		margin-bottom: $bsu * 1.5;
	}
}

// DARK VERSION
.o-theme-dark {

	.c-image-feature__layout {
		border: none;
	}

	.c-image-feature__heading,
	.c-image-feature__text {
		color: $white;

		a {
			color: $white;
			text-decoration: underline;
		}
	}

	.c-image-feature__heading::before {
		background-color: $white;
	}
}
