/* OBJECT.LAYOUT */

.o-layout-body {
	max-width: $viewport-max-width;
	margin: 0 auto;
	position: relative;
}

.o-layout-wrapper {
	@include layout-wrapper;

	// Remove padding on sides if a layout-wrapper is nested within another layout-wrapper
	.o-layout-wrapper {
		padding-left: 0;
		padding-right: 0;
	}
}

.o-layout-wrapper--narrow {
	max-width: $layout-wrapper-narrow-width;
}

.o-layout-page {
	position: relative;
	z-index: $z-page;

	padding-top: $bsu;
	@include bp("medium") {
		padding-top: $bsu * 3;
	}
}

.o-layout-content--has-aside {

	@include bp('large') {
		@include flex-extend;
	}
}

.o-layout-main {
	display: block;

	.o-layout-content--has-aside & {

		@include bp('large') {
			width: calc(100% - #{$layout-aside-width + $layout-aside-gutter-width});
		}
	}
}

.o-layout-aside {
	margin-top: $lsu;

	@include bp('large') {
		width: $layout-aside-width + $layout-aside-gutter-width;
		padding-left: $layout-aside-gutter-width;
		margin-top: 0;

		&.is-left {
			padding-left: 0;
			padding-right: $layout-aside-gutter-width;
		}
	}
}

.o-layout-header {
	margin-bottom: $lsu;
	@include bp("medium") {
		margin-bottom: $hsu;
	}
	text-align: center;

	> *:last-child {
		margin-bottom: 0;
	}
}

.o-layout-header--has-cta {
	// See _component.cta-intro.scss
	text-align: left;
	margin-bottom: $bsu;
}

.o-layout-header__subheading {
	display: block;
	margin-bottom: $ssu;
	@include font-18b;
}

.o-layout-header__heading {
	display: block;
	margin-bottom: 0.3em;
}

.o-layout-header__heading,
.o-layout-header__heading-text {
	@include font-heading-50;
}

.o-layout-header__heading-text {
	display: inline-block;
	vertical-align: middle;

	margin-right: $bsu;
}

.o-layout-header__highlight {
	display: inline-block;
	vertical-align: middle;

	@include font-heading-13;
	padding: $ssu;

	@supports (background-color: var(--supporting-1-a)) {
		height: 34px;
		position: relative;
		&::after {
			content: "";
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 100%;

			border-left: 10px solid var(--supporting-1-a);
			border-top: 17px solid var(--supporting-1-a);
			border-right: 10px solid transparent;
			border-bottom: 17px solid transparent;
		}
	}
}

.o-layout-header__lede {
	@include font-base-18--tall;
	width: 80%;
	margin: 0 auto;

	> *:last-child {
		margin-bottom: 0;
	}

	+ .c-expand-collapse {
		margin-top: $bsu;

		.c-accommodation__content-wrapper & {
			margin-top: $lsu;
			margin-bottom: ($bsu * 3);
		}
	}

	+ .o-body-text {
		margin-top: $bsu;
	}
}

.o-layout-header__lede--full {
	width: auto;
}

.o-layout-header__date {
	@include font-base-12;
	margin-bottom: 2em;
}