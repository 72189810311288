// CONTROL.EXPERIENCE-CAROUSEL

.c-experience-carousel {}

.c-experience-carousel__viewport {
	position: relative;
}

.c-experience-carousel__prev,
.c-experience-carousel__next {
	position: absolute;
	z-index: 1;
	top: 50%;
	transform: translateY(-50%);

	.is-inactive & {
		display: none;
	}

	@include bp-below("medium") {
		display: none;
	}
}

.c-experience-carousel__prev {
	left: 0;
}

.c-experience-carousel__next {
	right: 0;
}

.c-experience-carousel__list {
	@include flex-extend;
	flex-wrap: wrap;
}

.c-experience-carousel__item {
	padding: 0 $ssu;
	width: 100%;

	@include bp("medium") {
		width: percentage(calc(1/2));
	}

	@include bp("extra-large") {
		width: percentage(calc(1/3));
	}
}

.c-experience-carousel__all {
	@include flex-extend;
	justify-content: center;

	margin-top: $lsu;
}

.c-experience-carousel__list.flickity-enabled {
	display: block;

	@include bp("medium") {
		margin: 0 30px;

		.flickity-prev-next-button {
			&.previous {
				left: auto;
				right: calc(100% - 20px);
			}

			&.next {
				right: auto;
				left: calc(100% - 20px);
			}
		}
	}

	.flickity-viewport {
		&[style*="height"] {
			.c-experience-carousel__item {
				height: 100%;
			}
		}
	}
}
