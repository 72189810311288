﻿// MACRO.IMAGE

.m-image__lightbox-trigger {
	position: relative;

	&::after {
		@include font-icon;
		content: $iconf-plus;
		position: absolute;

		bottom: $bsu;
		right: $bsu;
	}
}
