﻿$weight--light: 300;
$weight--normal: 400;
$weight--medium: 500;
$weight--semibold: 600;
$weight--bold: 700 !default;
$weight--extrabold: 800;
$weight--black: 900;


// Font Faces

/*
	Font:		Montserrat
	URL:		https://fonts.google.com/specimen/Montserrat
	Licence:	SIL Open Font Licence Version 1.1
				/content/fonts/montserrat/ofl.txt
*/
@font-face {
	font-family: "Montserrat";
	src: url("/content/fonts/montserrat/montserrat-extrabold.eot");
	src: url("/content/fonts/montserrat/montserrat-extrabold.eot?#iefix") format("embedded-opentype"), url("/content/fonts/montserrat/montserrat-extrabold.woff2") format("woff2"), url("/content/fonts/montserrat/montserrat-extrabold.woff") format("woff"), url("/content/fonts/montserrat/montserrat-extrabold.ttf") format("truetype"), url("/content/fonts/montserrat/montserrat-extrabold.svg#montserratextrabold") format("svg");
	font-weight: $weight--normal;
	font-style: normal;
}

@font-face {
	font-family: "Montserrat";
	src: url("/content/fonts/montserrat/montserrat-extrabolditalic.eot");
	src: url("/content/fonts/montserrat/montserrat-extrabolditalic.eot?#iefix") format("embedded-opentype"), url("/content/fonts/montserrat/montserrat-extrabolditalic.woff2") format("woff2"), url("/content/fonts/montserrat/montserrat-extrabolditalic.woff") format("woff"), url("/content/fonts/montserrat/montserrat-extrabolditalic.ttf") format("truetype"), url("/content/fonts/montserrat/montserrat-extrabolditalic.svg#montserratextrabolditalic") format("svg");
	font-weight: $weight--normal;
	font-style: italic;
}

@font-face {
	font-family: "Montserrat";
	src: url("/content/fonts/montserrat/montserrat-black.eot");
	src: url("/content/fonts/montserrat/montserrat-black.eot?#iefix") format("embedded-opentype"), url("/content/fonts/montserrat/montserrat-black.woff2") format("woff2"), url("/content/fonts/montserrat/montserrat-black.woff") format("woff"), url("/content/fonts/montserrat/montserrat-black.ttf") format("truetype"), url("/content/fonts/montserrat/montserrat-black.svg#montserratblack") format("svg");
	font-weight: $weight--black;
	font-style: normal;
}

@font-face {
	font-family: "Montserrat";
	src: url("/content/fonts/montserrat/montserrat-blackitalic.eot");
	src: url("/content/fonts/montserrat/montserrat-blackitalic.eot?#iefix") format("embedded-opentype"), url("/content/fonts/montserrat/montserrat-blackitalic.woff2") format("woff2"), url("/content/fonts/montserrat/montserrat-blackitalic.woff") format("woff"), url("/content/fonts/montserrat/montserrat-blackitalic.ttf") format("truetype"), url("/content/fonts/montserrat/montserrat-blackitalic.svg#montserratblackitalic") format("svg");
	font-weight: $weight--black;
	font-style: italic;
}

@font-face {
	font-family: "Montserrat";
	src: url("/content/fonts/montserrat/montserrat-bold.eot");
	src: url("/content/fonts/montserrat/montserrat-bold.eot?#iefix") format("embedded-opentype"), url("/content/fonts/montserrat/montserrat-bold.woff2") format("woff2"), url("/content/fonts/montserrat/montserrat-bold.woff") format("woff"), url("/content/fonts/montserrat/montserrat-bold.ttf") format("truetype"), url("/content/fonts/montserrat/montserrat-bold.svg#montserratbold") format("svg");
	font-weight: $weight--bold;
	font-style: normal;
}

@font-face {
	font-family: "Montserrat";
	src: url("/content/fonts/montserrat/montserrat-medium.eot");
	src: url("/content/fonts/montserrat/montserrat-medium.eot?#iefix") format("embedded-opentype"), url("/content/fonts/montserrat/montserrat-medium.woff2") format("woff2"), url("/content/fonts/montserrat/montserrat-medium.woff") format("woff"), url("/content/fonts/montserrat/montserrat-medium.ttf") format("truetype"), url("/content/fonts/montserrat/montserrat-medium.svg#montserratmedium") format("svg");
	font-weight: $weight--medium;
	font-style: normal;
}

@font-face {
	font-family: "Montserrat";
	src: url("/content/fonts/montserrat/montserrat-semibold.eot");
	src: url("/content/fonts/montserrat/montserrat-semibold.eot?#iefix") format("embedded-opentype"), url("/content/fonts/montserrat/montserrat-semibold.woff2") format("woff2"), url("/content/fonts/montserrat/montserrat-semibold.woff") format("woff"), url("/content/fonts/montserrat/montserrat-semibold.ttf") format("truetype"), url("/content/fonts/montserrat/montserrat-semibold.svg#montserratsemibold") format("svg");
	font-weight: $weight--semibold;
	font-style: normal;
}

@font-face {
	font-family: "Montserrat";
	src: url("/content/fonts/montserrat/montserrat-semibolditalic.eot");
	src: url("/content/fonts/montserrat/montserrat-semibolditalic.eot?#iefix") format("embedded-opentype"), url("/content/fonts/montserrat/montserrat-semibolditalic.woff2") format("woff2"), url("/content/fonts/montserrat/montserrat-semibolditalic.woff") format("woff"), url("/content/fonts/montserrat/montserrat-semibolditalic.ttf") format("truetype"), url("/content/fonts/montserrat/montserrat-semibolditalic.svg#montserratsemibolditalic") format("svg");
	font-weight: $weight--semibold;
	font-style: italic;
}

@font-face {
	font-family: "Montserrat";
	src: url("/content/fonts/montserrat/montserrat-regular.eot");
	src: url("/content/fonts/montserrat/montserrat-regular.eot?#iefix") format("embedded-opentype"), url("/content/fonts/montserrat/montserrat-regular.woff2") format("woff2"), url("/content/fonts/montserrat/montserrat-regular.woff") format("woff"), url("/content/fonts/montserrat/montserrat-regular.ttf") format("truetype"), url("/content/fonts/montserrat/montserrat-regular.svg#montserratregular") format("svg");
	font-weight: $weight--normal;
	font-style: normal;
}

@font-face {
	font-family: "Montserrat";
	src: url("/content/fonts/montserrat/montserrat-italic.eot");
	src: url("/content/fonts/montserrat/montserrat-italic.eot?#iefix") format("embedded-opentype"), url("/content/fonts/montserrat/montserrat-italic.woff2") format("woff2"), url("/content/fonts/montserrat/montserrat-italic.woff") format("woff"), url("/content/fonts/montserrat/montserrat-italic.ttf") format("truetype"), url("/content/fonts/montserrat/montserrat-italic.svg#montserratitalic") format("svg");
	font-weight: $weight--normal;
	font-style: italic;
}



/*
	Font:		Open Sans
	URL:		https://fonts.google.com/specimen/Open+Sans
	Licence:	Apache License Version 2.0
				/content/fonts/open-sans/LICENSE.txt
*/
@font-face {
	font-family: "Open Sans";
	src: url("/content/fonts/open-sans/opensans-regular.eot");
	src: url("/content/fonts/open-sans/opensans-regular.eot?#iefix") format("embedded-opentype"), url("/content/fonts/open-sans/opensans-regular.woff2") format("woff2"), url("/content/fonts/open-sans/opensans-regular.woff") format("woff"), url("/content/fonts/open-sans/opensans-regular.ttf") format("truetype"), url("/content/fonts/open-sans/opensans-regular.svg#open_sansregular") format("svg");
	font-weight: $weight--normal;
	font-style: normal;
}

@font-face {
	font-family: "Open Sans";
	src: url("/content/fonts/open-sans/opensans-italic.eot");
	src: url("/content/fonts/open-sans/opensans-italic.eot?#iefix") format("embedded-opentype"), url("/content/fonts/open-sans/opensans-italic.woff2") format("woff2"), url("/content/fonts/open-sans/opensans-italic.woff") format("woff"), url("/content/fonts/open-sans/opensans-italic.ttf") format("truetype"), url("/content/fonts/open-sans/opensans-italic.svg#open_sansitalic") format("svg");
	font-weight: $weight--normal;
	font-style: italic;
}

@font-face {
	font-family: "Open Sans";
	src: url("/content/fonts/open-sans/opensans-bold.eot");
	src: url("/content/fonts/open-sans/opensans-bold.eot?#iefix") format("embedded-opentype"), url("/content/fonts/open-sans/opensans-bold.woff2") format("woff2"), url("/content/fonts/open-sans/opensans-bold.woff") format("woff"), url("/content/fonts/open-sans/opensans-bold.ttf") format("truetype"), url("/content/fonts/open-sans/opensans-bold.svg#open_sansbold") format("svg");
	font-weight: $weight--bold;
	font-style: normal;
}

@font-face {
	font-family: "Open Sans";
	src: url("/content/fonts/open-sans/opensans-bolditalic.eot");
	src: url("/content/fonts/open-sans/opensans-bolditalic.eot?#iefix") format("embedded-opentype"), url("/content/fonts/open-sans/opensans-bolditalic.woff2") format("woff2"), url("/content/fonts/open-sans/opensans-bolditalic.woff") format("woff"), url("/content/fonts/open-sans/opensans-bolditalic.ttf") format("truetype"), url("/content/fonts/open-sans/opensans-bolditalic.svg#open_sansbold_italic") format("svg");
	font-weight: $weight--bold;
	font-style: italic;
}


// Font Faces

/*
	Font:		Oxygen
	URL:		https://fonts.google.com/specimen/Oxygen
	Licence:	Apache License Version 2.0
				/content/fonts/oxygenbold/sil open font license.txt
*/

@font-face {
	font-family: 'Oxygen';
	src: url('/content/fonts/oxygen/oxygen-bold.woff2') format('woff2'), url('/content/fonts/oxygen/oxygen-bold.woff') format('woff');
	font-weight: $weight--normal;
	font-style: normal;
}


@font-face {
	font-family: 'Oxygen';
	src: url('/content/fonts/oxygen/oxygen-regular.woff2') format('woff2'), url('/content/fonts/oxygen/oxygen-regular.woff') format('woff');
	font-weight: $weight--normal;
	font-style: normal;
}

// Font Faces

/*
	Font:		Oswald
	URL:		https://www.fontsquirrel.com/fonts/oswald
	Licence:	SIL Open Font Licence Version 1.1
				/content/fonts/oswald/sil open font license.txt
*/
@font-face {
	font-family: "Oswald";
	src: url("/content/fonts/oswald/oswald-bold.eot");
	src: url("/content/fonts/oswald/oswald-bold.eot?#iefix") format("embedded-opentype"), url("/content/fonts/oswald/oswald-bold.woff2") format("woff2"), url("/content/fonts/oswald/oswald-bold.woff") format("woff"), url("/content/fonts/oswald/oswald-bold.ttf") format("truetype"), url("/content/fonts/oswald/oswald-bold.svg#oswaldbold") format("svg");
	font-weight: $weight--normal;
	font-style: normal;
}

@font-face {
	font-family: "Oswald";
	src: url("/content/fonts/oswald/oswald-bolditalic.eot");
	src: url("/content/fonts/oswald/oswald-bolditalic.eot?#iefix") format("embedded-opentype"), url("/content/fonts/oswald/oswald-bolditalic.woff2") format("woff2"), url("/content/fonts/oswald/oswald-bolditalic.woff") format("woff"), url("/content/fonts/oswald/oswald-bolditalic.ttf") format("truetype"), url("/content/fonts/oswald/oswald-bolditalic.svg#oswaldbolditalic") format("svg");
	font-weight: $weight--normal;
	font-style: italic;
}

@font-face {
	font-family: "Oswald";
	src: url("/content/fonts/oswald/oswald-heavy.eot");
	src: url("/content/fonts/oswald/oswald-heavy.eot?#iefix") format("embedded-opentype"), url("/content/fonts/oswald/oswald-heavy.woff2") format("woff2"), url("/content/fonts/oswald/oswald-heavy.woff") format("woff"), url("/content/fonts/oswald/oswald-heavy.ttf") format("truetype"), url("/content/fonts/oswald/oswald-heavy.svg#oswaldheavy") format("svg");
	font-weight: $weight--normal;
	font-style: normal;
}

@font-face {
	font-family: "Oswald";
	src: url("/content/fonts/oswald/oswald-heavyitalic.eot");
	src: url("/content/fonts/oswald/oswald-heavyitalic.eot?#iefix") format("embedded-opentype"), url("/content/fonts/oswald/oswald-heavyitalic.woff2") format("woff2"), url("/content/fonts/oswald/oswald-heavyitalic.woff") format("woff"), url("/content/fonts/oswald/oswald-heavyitalic.ttf") format("truetype"), url("/content/fonts/oswald/oswald-heavyitalic.svg#oswaldheavyitalic") format("svg");
	font-weight: $weight--normal;
	font-style: italic;
}


/*
	Font:		Permanent Marker
	URL:		https://fonts.google.com/specimen/Permanent+Marker
	Licence:	Apache License Version 2.0
				/content/fonts/permanent-marker/LICENSE.txt
*/

@font-face {
	font-family: "Permanent Marker";
	src: url("/content/fonts/permanent-marker/permanentmarker-regular.eot");
	src: url("/content/fonts/permanent-marker/permanentmarker-regular.eot?#iefix") format("embedded-opentype"), url("/content/fonts/permanent-marker/permanentmarker-regular.woff2") format("woff2"), url("/content/fonts/permanent-marker/permanentmarker-regular.woff") format("woff"), url("/content/fonts/permanent-marker/permanentmarker-regular.ttf") format("truetype"), url("/content/fonts/permanent-marker/permanentmarker-regular.svg#permanent_markerregular") format("svg");
	font-weight: $weight--normal;
	font-style: normal;
}



// BASE.TYPOGRAPHY

// Config
//-------
// Base settings all set on the HTML element
// $base-font-size is used throughout to calculate em/rem sizes

$base-font-family: "Lucida Grande", "Lucida Sans Unicode", "Open Sans", Arial, Helvetica, sans-serif;
$heading-font-family: "Montserrat", Arial, Helvetica, sans-serif;

$base-font-size: 16px;
$base-line-height: 22px;


// Style declarations
//
//	.c-component__heading {
//		@include font-base-12;
//	}

@mixin font-base-28b {
	font-family: $base-font-family;
	font-size: 28px;
	font-weight: $weight--bold;
	line-height: lh(36, 28);
}

@mixin font-base-20--tall {
	font-family: $base-font-family;
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: lh(32, 20);
}

@mixin font-base-20b {
	font-family: $base-font-family;
	font-size: 20px;
	font-weight: $weight--bold;
	line-height: lh(28, 20);
}

@mixin font-base-18 {
	font-family: $base-font-family;
	font-size: 18px;
	font-weight: $weight--normal;
	line-height: lh(26, 18);
}


@mixin font-base-18--tall {
	font-family: $base-font-family;
	font-size: 18px;
	font-weight: $weight--normal;
	line-height: lh(32, 18);
}

@mixin font-base-16sb {
	font-family: $base-font-family;
	font-size: 16px;
	font-weight: $weight--semibold;
	line-height: lh(22, 16);
}

@mixin font-base-14 {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--normal;
	line-height: lh(18, 14);
}

@mixin font-base-14--tall {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--normal;
	line-height: lh(18, 14);
}

@mixin font-base-14sb {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--semibold;
	line-height: lh(18, 14);
}

@mixin font-base-14b {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--bold;
	line-height: lh(18, 14);
}

@mixin font-base-12 {
	font-family: $base-font-family;
	font-size: 12px;
	font-weight: $weight--normal;
	line-height: lh(14, 12);
}

@mixin font-base-12-caps {
	@include font-base-12;
	text-transform: uppercase;
}

@mixin font-base-12b {
	font-family: $base-font-family;
	font-size: 12px;
	font-weight: $weight--bold;
	line-height: lh(14, 12);
}

@mixin font-base-10 {
	font-family: $base-font-family;
	font-size: 10px;
	font-weight: $weight--normal;
	line-height: lh(12, 10);
}

@mixin font-base-10sb {
	font-family: $base-font-family;
	font-size: 10px;
	font-weight: $weight--semibold;
	line-height: lh(12, 10);
}


@mixin font-body-text {
	font-family: $base-font-family;
	font-size: 15px;
	font-weight: $weight--normal;
	line-height: lh(26px, 15px);
}



@mixin font-heading-72 {
	font-family: $heading-font-family;
	font-size: 72px;
	font-weight: $weight--bold;
	line-height: lh(72, 72);
}

@mixin font-heading-50 {
	font-family: $heading-font-family;
	font-size: 50px;
	font-weight: $weight--bold;
	line-height: lh(58, 50);
}

@mixin font-heading-36 {
	font-family: $heading-font-family;
	font-size: 36px;
	font-weight: $weight--bold;
	line-height: lh(42, 36);
}

@mixin font-heading-28 {
	font-family: $heading-font-family;
	font-size: 28px;
	font-weight: $weight--bold;
	line-height: lh(36, 28);
}

@mixin font-heading-24 {
	font-family: $heading-font-family;
	font-size: 24px;
	font-weight: $weight--bold;
	line-height: lh(28, 24);
}

@mixin font-heading-20 {
	font-family: $heading-font-family;
	font-size: 20px;
	font-weight: $weight--bold;
	line-height: lh(28, 20);
}

@mixin font-heading-18 {
	font-family: $heading-font-family;
	font-size: 18px;
	font-weight: $weight--bold;
	line-height: lh(20, 18);
}

@mixin font-heading-16 {
	font-family: $heading-font-family;
	font-size: 16px;
	font-weight: $weight--bold;
	line-height: lh(18, 16);
}

@mixin font-heading-15 {
	font-family: $heading-font-family;
	font-size: 15px;
	font-weight: $weight--bold;
	line-height: lh(18, 15);
}

@mixin font-heading-14 {
	font-family: $heading-font-family;
	font-size: 14px;
	font-weight: $weight--normal;
	line-height: lh(18, 14);
}

@mixin font-heading-14--tall {
	font-family: $heading-font-family;
	font-size: 14px;
	font-weight: $weight--normal;
	line-height: lh(32, 14);
}

@mixin font-heading-13 {
	font-family: $heading-font-family;
	font-size: 13px;
	font-weight: $weight--normal;
	line-height: lh(15, 13);
}

@mixin font-36b {
	font-family: $base-font-family;
	font-size: 36px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-32 {
	font-family: $base-font-family;
	font-size: 32px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

@mixin font-32b {
	font-family: $base-font-family;
	font-size: 32px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-24 {
	font-family: $base-font-family;
	font-size: 24px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

@mixin font-24b {
	font-family: $base-font-family;
	font-size: 24px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-20 {
	font-family: $base-font-family;
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

@mixin font-20-tall {
	font-family: $base-font-family;
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: 1.4;
	letter-spacing: 0.01em;
}

@mixin font-20b {
	font-family: $base-font-family;
	font-size: 20px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-18b {
	font-family: $base-font-family;
	font-size: 18px;
	font-weight: $weight--bold;
	line-height: 1.35;
}

@mixin font-16 {
	font-family: $base-font-family;
	font-size: 16px;
	font-weight: $weight--normal;
	line-height: 1.5;
	letter-spacing: 0.01em;
}

@mixin font-16b {
	font-family: $base-font-family;
	font-size: 16px;
	font-weight: $weight--bold;
	line-height: 1.5;
	letter-spacing: 0.01em;
}

@mixin font-14b {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--bold;
	line-height: 1.5;
}

@mixin font-14-caps {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--normal;
	text-transform: uppercase;
	line-height: 1.15;
}

@mixin font-12b {
	font-family: $base-font-family;
	font-size: 12px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

html {
	font-family: $base-font-family;
	color: $body-color;
}

p {
	margin-top: 0;
}
