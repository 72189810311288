﻿// THEME
$grey--darkest: #333333;
$yellow: #FFD920;

$blue--dark: #003C5A;

$brand: #00a0dd;
$brand--alt: #0080b1;

// Assign colours to common variables
$body-color: $grey--darkest;
