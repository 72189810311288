// CONTROL.RESOURCES

$resources-bp: "medium";

.c-resources {
	&:first-child {
		margin-top: 0;
	}
}

.c-resources__list {
	@include list-reset;

	@include flex-extend;
	align-items: stretch;
	flex-wrap: wrap;

	margin-left: -$ssu;
	margin-top: -$ssu;
}

.c-resources__item-layout {
	padding-left: $ssu;
	padding-top: $ssu;

	width: 100%;
}

.o-layout-content--has-aside {
	.c-resources__item-layout {
		width: 100%;
	}
}

.c-resources__item {
	@include flex-extend;
	align-items: stretch;

	border: 1px solid $grey--light;

	@include bp-below($resources-bp) {
		flex-direction: column;
	}
}

.c-resources__media {
	@include figure;
	@include flex-extend;
	align-items: stretch;

	@include bp($resources-bp) {
		width: 100px;
	}
}

.c-resources__body {
	flex-grow: 1;
	@include flex-extend;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	@include bp($resources-bp) {
		flex-wrap: nowrap;
	}

	padding: ($ssu * 1.5) $bsu;
	background: $white;
}

.c-resources__details {
	flex-grow: 1;
	width: 100%;
	@include bp($resources-bp) {
		width: auto;
	}
}

.c-resources__brand {
	display: block;
	color: $brand;
	@include font-12b;
	margin-bottom: $tsu;
}

.c-resources__title {
	@include font-16b;
}

.c-resources__type-list {
	@include list-reset;
	@include flex-extend;
	flex-wrap: wrap;

	margin-left: -15px;
	margin-top: $ssu - $ssu;
	@include bp($resources-bp) {
		margin-top: -$ssu;
	}

	margin-right: $bsu;
}

// Single type
.c-resources__type {
	margin-right: $bsu;
	margin-top: $ssu;
	@include bp($resources-bp) {
		margin-top: 0;
	}
}

// Multiple types
.c-resources__type-list .c-resources__type {
	margin-right: 0;
	margin-top: 0;

	padding-left: 15px;
	padding-top: $ssu;

	@include flex-extend;
	align-items: center;
}

.c-resources__type-label {
	@include flex-extend;
	align-items: center;
	cursor: pointer;

	.c-fake-checkbox__display {
		margin-right: $tsu;
	}
}

.c-resources__type-text {
	@include font-base-14;
}


.c-resources__actions {
	@include flex-extend;
	align-items: center;

	margin-top: $ssu;
	@include bp($resources-bp) {
		margin-top: 0;
	}
}
