// COMPONENT.CTA-INTRO

.c-cta-intro__cta-subheading {
	@include font-base-12;

	@supports (color: var(--secondary-a)) {
		color: var(--secondary-a)
	}
}

.c-cta-intro__cta-heading {
	display: block;

	&.t-link {
		@supports (color: var(--link--hover)) {
			color: var(--link--hover);
		}

		&:hover {
			@supports (color: var(--link)) {
				color: var(--link);
			}
		}
	}
}
