// OBJECT.BTN

// Shared button styles

.o-btn,
.umbraco-forms-form .btn {
	@include link-transition;
	display: inline-block;

	@include font-heading-16;
	padding: 18px 10px;
	@include bp("medium") {
		padding: 18px 23px;
	}

	border: 0;
	border-radius: 3px;

	text-align: center;
	text-decoration: none;
	vertical-align: middle;

	cursor: pointer;
	&:disabled {
		cursor: not-allowed;

		background: $white !important;
		color: $grey !important;
		border: none !important;
		box-shadow: inset 0 0 0 1px $grey--light !important;
	}
}

// For vertical alignment of buttons with icon and text
.o-btn__icon,
.o-btn__text {
	display: inline-block;
	vertical-align: middle;
}
.o-btn__icon + .o-btn__text,
.o-btn__text + .o-btn__icon {
	margin-left: $tsu;
}

.o-btn__icon {
	font-size: 1.2em;
}

.o-btn--full {
	display: block;
	width: 100%;
}

.o-btn--nowrap {
	white-space: nowrap;
}

.o-btn--reset {
	padding: 0;
	border: 0;
	background: none;
}


// Buttons that contain only an icon use different padding
// Apply .o-btn--icon in addition to a theme and a size
.o-btn--icon {
	&.o-btn--large {
		height: 74px;
		width: 74px;
		font-size: 28px;
		line-height: #{74px - (23px * 2)};
		padding: 23px;
	}
	&, &.o-btn--medium {
		height: 54px;
		width: 54px;
		font-size: 24px;
		line-height: #{54px - (15px * 2)};
		padding: 15px;
	}
	&.o-btn--small {
		height: 40px;
		width: 40px;
		font-size: 16px;
		line-height: #{40px - (11px * 2)};
		padding: 11px;
	}
	&.o-btn--smallest {
		height: 30px;
		width: 30px;
		font-size: 14px;
		line-height: #{30px - (6px * 2)};
		padding: 6px;
	}
}

////////////
// THEMES //
////////////

// See ~/Views/Housing/ThemeStyle.cshtml for button theme CSS

// Text
.o-btn--text {
	padding: 0;
	border: none;
	background-color: transparent;
}

.o-btn--icon-before {
	position: relative;
	padding-left: 2em;
}

.o-btn--icon-before [class*="iconf"],
.o-btn--icon-after [class*="iconf"] {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.o-btn--icon-before [class*="iconf"] {
	left: 0;
}

.o-btn--icon-after [class*="iconf"] {
	right: 0;
}

///////////
// SIZES //
///////////
.o-btn--large {
	@include font-heading-18;
	padding: 24px $lsu;
}

.o-btn--medium {
	// This is the default size, all inherited from .o-btn
}

.o-btn--small {
	@include font-heading-14;
	padding: 11px 17px;
}

.o-btn--smallest {
	@include font-base-12;
	padding: 8px 12px;
}

.o-btn--text {
	@include font-heading-14;
}
