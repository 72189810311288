// COMPONENT.BASIC-FILTER

.c-basic-filter {
	@include list-reset;

	@include flex-extend;
	justify-content: center;
	flex-wrap: wrap;
	margin: ($bsu - calc($ssu / 2)) 0;
}

.c-basic-filter__item {
	padding: calc($ssu / 2);
}
