// COMPONENT.FOOTER

$footer-bp--mobile: "medium";
$footer-bp: "extra-large";
$footer-logo-width: 150px;

.c-footer {
	position: relative;

	@include bp($footer-bp--mobile) {
		border-top: 2px solid $grey--light;
	}

	@include bp($footer-bp) {
		@include flex-extend;
		flex-wrap: wrap;
		align-items: stretch;
	}
}

.c-footer__primary {
	@include bp($footer-bp) {
		@include flex-extend;
		flex-direction: column;
		position: relative;
		overflow: hidden;

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 100%;
			height: 107%;
			width: 500px;
			background: $white;
			transform-origin: 0 0;
			transform: rotate(20deg);
		}
	}
}

.c-footer__secondary {
	background: $white;
}

.c-footer__primary-head {
	@include font-heading-13;

	.c-footer__primary-layout {
		> p:last-child {
			margin-bottom: 0;
		}
	}
}

.c-footer__primary-body {
	flex-grow: 1;

	.c-footer__primary-layout {
		@include flex-extend;
		flex-wrap: wrap;
		justify-content: flex-start;

		@include bp("large") {
			justify-content: flex-end;
		}
	}
}

.c-footer__primary-layout {
	padding: $bsu;
}

.c-footer__secondary-layout {
	padding: $bsu;
}

@include bp($footer-bp) {
	.c-footer__primary {
		width: 65%;
	}

	.c-footer__primary-layout {
		@include layout-split(left, 65%);
		padding: $bsu 200px $bsu $bsu;
	}

	.c-footer__secondary {
		width: 35%;
	}

	.c-footer__secondary-layout {
		@include layout-split(right, 35%);
		height: 100%;
		@include flex-extend;
	}
}

.c-footer__social {
	flex: 1 1 auto;
	margin-bottom: $bsu;

	@include bp("small") {
		flex: 0 0 auto;
		width: 50%;
		margin-bottom: 0;
	}

	@include bp("large") {
		width: percentage(calc(2/5));
	}
}

.c-footer__social-subheading {
	display: block;
	margin-bottom: $ssu;

	@include font-heading-13;
}

.c-footer__social-heading {
	display: block;
	margin-bottom: $bsu;

	@include font-heading-28;
}

.c-footer__social-item {
	display: inline-block;
	margin-right: $tsu;
	margin-bottom: $tsu;
}

.c-footer__social-link {
	@include circle(40px);
	@include link-transition;
	border: 1px solid $white;
	font-size: 20px;
	color: $white;
	background-color: transparent;

	&:hover,
	&:focus {
		color: $brand;
		background-color: $white;
	}
}

.c-footer__navigation {
	column-count: 2;
	column-gap: $lsu;
	margin-bottom: $bsu;
	flex: 1 1 auto;

	@include bp("small") {
		flex: 0 0 auto;
		width: 50%;
	}

	@include bp("medium") {
		padding-left: $lsu;
		margin-bottom: 0;
	}

	@include bp("large") {
		width: percentage(calc(3/5));
		padding-left: $hsu;
	}
}

.c-footer__navigation-link {
	@include font-heading-13;
	display: inline-block;
	padding: $tsu 0;
}

.c-footer__legal {
	@include flex-extend;
	flex-wrap: wrap;
	align-items: center;

	@include bp("large") {
		width: percentage(calc(3/5));
		padding-left: $hsu;
	}
}

.c-footer__legal-text,
.c-footer__legal-link {
	@include font-base-10;
	margin-right: $bsu;
	margin-bottom: $ssu;

	&:last-child {
		margin-right: 0;
	}
}

.c-footer__logo-list-layout {
	display: flex;
	margin: 0 auto;
	max-width: 150px * 3;
	width: 100%;
}

.c-footer__logo-list {
	flex-grow: 1;

	@include list-reset;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: $bsu;

	@include bp("small") {
		padding: 0 $lsu;
	}

	@include bp($footer-bp--mobile) {
		padding: 0;
	}
}

.c-footer__logo-item {
	display: flex;
	justify-content: center;
	align-items: center;

	.c-footer__logo-item-image {
		background-color: transparent !important;
		padding-bottom: 100% !important;
	}

	.o-fit__image {
		object-fit: contain;
	}
}


.c-footer__tertiary {
	width: 100%;
	overflow: hidden;

	@include bp($footer-bp--mobile) {
		border-top: 2px solid $grey--light;
	}
}

.c-footer__tertiary-layout {
	@include bp($footer-bp) {
		@include layout-wrapper;
		@include flex-extend;
		align-items: stretch;
		position: relative;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			right: calc(100% - #{$bsu});
			background: $grey--lightest;
			width: 50vw;
		}
	}
}

.c-footer__logo {
	flex-shrink: 0;

	@include flex-extend;
	align-items: center;
	justify-content: center;
	padding: $bsu;
	background: $grey--lightest;

	@include bp($footer-bp) {
		justify-content: flex-start;
		padding: $bsu $hsu $bsu 0;
		margin-right: $hsu;
		position: relative;
		overflow: hidden;

		&::after {
			content: "";
			position: absolute;
			top: 0;
			left: 100%;
			height: 107%;
			width: 200px;
			background: $white;
			transform-origin: 0 0;
			transform: rotate(20deg);
		}
	}
}

.c-footer__logo-link {
	display: block;
}

.c-footer__logo-image {
	display: block;
	width: 163px;
	height: 35px;

	@include bp-below($footer-bp) {
		margin: 0 auto;
	}
}

.c-footer__sibling-list {
	@include list-reset;
	@include flex-extend;

	@include bp-below($footer-bp) {
		flex-wrap: wrap;
		justify-content: center;
		padding: $ssu;
	}

	@include bp-below("medium") {
		padding: 0;
	}
}

.c-footer__sibling-item {
	max-width: 90px;
	@include flex-extend;
	align-items: center;
	margin: $ssu;

	img {
		max-height: 50px;
		width: auto;
		max-width: 100%;
		min-width: 50px;
	}

	@include bp("medium") {
		max-width: 110px;
	}

	@include bp($footer-bp) {
		margin: $bsu;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

.c-footer__sibling-link {
	display: block;
}

.c-footer__sibling-image {
}

.c-footer__sticky {
	@include flex-extend;

	@include bp("medium") {
		display: none;
	}

	position: fixed;
	z-index: 1;
	left: 0;
	bottom: 0;
	width: 100%;
	background: rgba($white, 0.8);
	padding: $tsu 0;
	align-items: center;
	justify-content: space-between;
}

.c-footer__sticky-button {
	display: block;
	margin: 0 $tsu;
	width: 50%;
}

.c-footer__sticky-spacer {
	@include bp("medium") {
		display: none;
	}

	height: 50px;
}

.c-footer-back-to-top {
	display: block;
	position: fixed;
	bottom: 60px;
	right: 0;
	padding: $ssu 0;
	z-index: $z-back-to-top;
	@include square(44px);
	font-size: 24px;

	@include bp("medium") {
		@include square(54px);
		font-size: 32px;
	}

	border-radius: 3px 0 0 3px;
	box-shadow: 0 0 1px 1px rgba($white, 0.2);
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.5s linear,visibility 0s linear 0.5s;

	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}

	&.fade-in {
		opacity: 1;
		visibility: visible;
		transition-delay: 0s;
	}

	@include bp("large") {
		position: relative;
		display: none;
		bottom: 0;
		vertical-align: bottom;
		border-bottom: 0;
		height: 30px;
		opacity: 1;
		visibility: visible;

		&.sticky-on-desktop {
			display: inline-block;
			opacity: 0;
			visibility: hidden;

			&.fade-in {
				position: fixed;
				bottom: auto;
				top: 50%;
				transform: translateY(-50%);
				width: 54px;
				height: 54px;
				border: none;
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
