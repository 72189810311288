.c-home-intro {

	@include bp('medium') {
		background-image: url(/Content/images/interface/bg/punga-bg-map-1024.png);
		background-position: top center;
		background-repeat: no-repeat;
		background-size: cover;
		padding: $bsu 0;
	}

	@include bp('large') {
		background-image: url(/Content/images/interface/bg/punga-bg-map-1440.png);
		padding: ($bsu * 7) 0 ($bsu * 5);
	}

	@include bp('extra-large') {
		background-image: url(/Content/images/interface/bg/punga-bg-map-1920.png);
	}
}

.c-home-intro__body-layout {
	padding: $bsu 0;

	@include bp('LARGE') {
		padding: $Hsu 0;
	}
}

.c-home-intro__body-container {
	@include font-body-text;
	flex-direction: row;

	@include bp('medium') {
		flex-direction: row-reverse;
	}

	.o-grid__item {
		z-index: 1;
	}
}

.c-home-intro__img-container-right {
	margin-top: $bsu;
	margin-left: -$lsu;
}

.c-home-intro__item-image {
	margin-bottom: $bsu;
}

.c-home-intro__body-title {
	@include font-heading-28;
	margin-bottom: $bsu;

	@include bp('large') {
		@include font-heading-50;
	}

	&::after {
		content: "";
		display: block;
		width: 80px;
		border-bottom: 4px solid var(--primary-a);

		@include bp('large') {
			display: none;
		}
	}
}

.c-home-intro__body-text {
	margin-bottom: $bsu;

	@include bp('medium') {
		margin-bottom: $lsu;
	}
}