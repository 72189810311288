.c-food-menu {
	margin: 0 auto;
}

.c-food-menu__tab-list {
	@include list-reset;
	@include flex-extend;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.c-food-menu__tab-item {
	margin-right: $bsu;
	margin-bottom: $bsu;
}

.c-food-menu__tab-button {
	@include button-reset;
	padding: ($ssu * 1.5) $ssu;

	@include bp("large") {
		padding: ($ssu * 1.5) $bsu;
	}

	@include font-heading-20;
	color: $brand;
	position: relative;

	&::before,
	&::after {
		content: "";
		@include abs-center-h;
		width: 0;
		height: 4px;
		opacity: 0;
		background: $black;

		@include link-transition;
	}

	&::before {
		top: 0;
	}


	&::after {
		bottom: 0;
	}

	&[aria-pressed="true"],
	&:hover,
	&:focus {
		color: $black;
		outline: none;

		&::before,
		&::after {
			width: 100%;
			opacity: 1;
		}
	}
}

.c-food-menu__tab-body {
	&[aria-expanded="false"] {
		display: none;
	}
	&[aria-expanded="true"] {
		display: block;
	}
}

.c-food-menu__section {
	margin-top: $lsu;
	&:first-child {
		margin-top: 0;
	}
}

.c-food-menu__section-title {
	@include font-36b;
	color: $black;
	margin-bottom: $bsu;
}

.c-food-menu__items {
	@include list-reset;
}

.c-food-menu__item {
	border-bottom: 2px dotted $grey--dark;
	@include flex-extend;
	justify-content: space-between;

	padding: $bsu 0;
	&:first-child {
		padding-top: 0;
	}
}

.c-food-menu__item-details {
	padding-right: $bsu;
}

.c-food-menu__item-name {
	display: block;
	@include font-16b;
	color: $brand;
}

.c-food-menu__item-description {
	display: block;
	@include font-16;
	color: $grey--dark;
}

.c-food-menu__item-prices {
	@include list-reset;
}

.c-food-menu__item-price {
	@include flex-extend;
	justify-content: space-between;
}

.c-food-menu__item-price-name {
	@include font-16;
	color: $grey--dark;
	padding-right: $bsu;
}

.c-food-menu__item-price-amount {
	@include font-16b;
}

.c-food-menu__notes {
	margin: $bsu 0;
}

.c-food-menu__intro {
	@include font-20;
	margin-top: $ssu*3;
	margin-bottom: $ssu;
	color: $grey--dark;

	p {
		margin-bottom: 0;
	}

	& + .c-food-menu__section {
		margin-top: $bsu;
	}
}