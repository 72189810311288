﻿// CONTROL.EXPAND-COLLAPSE
.c-expand-collapse {
	.c-accommodation__content-wrapper & {
		margin-top: ($bsu*3);
		margin-bottom: ($bsu*3);
	}
}

.c-expand-collapse__item-header {
	@include link-transition;
	position: relative;
	padding: ($ssu * 1.5) $bsu;
	padding-right: $bsu * 3;
	background-color: $white;
	cursor: pointer;
	outline: none;

	&:hover,
	&:focus {
		color: $brand;
	}
}

.c-expand-collapse__item-btn {
	@include font-body-text;
	display: flex;
	margin: $ssu 0 0;
	padding-top: 1px;
	padding-bottom: 1px;
	justify-content: center;
	align-items: center;
}

.c-expand-collapse__item-icon {
	font-size: 14px;

	.c-expand-collapse__item.is-expanded &,
	.c-expand-collapse__item[aria-expanded="true"] & {

		&:before {
			content: $iconf-minus;
		}
	}
}

.c-expand-collapse__item-button {
	@include font-heading-14;
	font-weight: $weight--bold;
}

.c-expand-collapse__item-btn--open {

	.c-expand-collapse__item[aria-expanded="true"] & {
		display: none;
	}
}

.c-expand-collapse__item-btn--close {
	display: none;

	.c-expand-collapse__item[aria-expanded="true"] & {
		display: inline-block;
	}
}

.c-expand-collapse__item-body {
	padding: $ssu 0;

	.c-expand-collapse__item[aria-expanded="false"] & {
		display: none;
	}

	.c-expand-collapse__item[aria-expanded="true"] & {
		display: block;
	}
}
