// COMPONENT.HERO-HOME

$hero-home-bp: "medium";

.c-hero-home {
	position: relative;

	@include bp($hero-home-bp) {
		margin-bottom: 0;
	}
	@include bp("large") {
		margin-bottom: 0;
	}
}



.c-hero-home__body {
	position: absolute;
	bottom: $hsu;
	@include bp($hero-home-bp) {
		bottom: 40%;
	}
	max-width: 670px;
	@include bp(1074px) {
		max-width: 720px;
	}
}

.c-hero-home__heading {
	display: block;
	@include font-heading-36;
	@include bp("medium") {
		@include font-heading-50;
	}
	@include bp("large") {
		@include font-heading-72;
	}

	color: $white;
}

.c-hero-home__foot {
	@include bp($hero-home-bp) {
		position: absolute;
		left: 0;
		top: calc(100% - 150px);
		padding-top: 150px;
		width: 100%;
		height: 150px;

		background: linear-gradient(to bottom, rgba($blue--dark,0) 0%, rgba($blue--dark,0.8) 95%, $blue--dark 100% );

		/*background: url(/content/images/interface/bg/bg-hero-map-1024.png) top center no-repeat;

		@include bp(1024px) {
			background-image: url(/content/images/interface/bg/bg-hero-map-1440.png);
		}

		@include bp(1440px) {
			background-image: url(/content/images/interface/bg/bg-hero-map-1920.png);
		}*/
	}
}

.c-hero-home__promo-wrapper {
	@include flex-extend;

	@include bp("medium") {
		@include bp-below(900px) {
			display: none;
		}
	}
}

.c-hero-home__promo {
	align-items: center;
}

.c-hero-home__promo-subheading {
	@include font-base-12;
}

.c-hero-home__promo-heading {
	@include font-heading-20;

	@include bp("small") {
		@include font-heading-28;
	}

	@include link-transition;

	&::after {
		@include font-icon;
		content: $iconf-arrow-right;
		font-size: 0.8em;
		margin-left: $ssu;

		@supports (color: var(--link)) {
			color: var(--link);
		}
	}
}

.c-hero-home__main {
	position: relative;
	// Make sure controls after the hero aren't pushed behind it
}