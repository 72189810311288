﻿// CONTROL.IMAGE-GALLERY

.c-image-gallery {
	.flickity-prev-next-button {
		top: calc(50% - 29px);
	}
}

.c-image-gallery__viewport {
	position: relative;
}

.c-image-gallery__prev,
.c-image-gallery__next {
	position: absolute;
	z-index: 1;
	top: 50%;
	transform: translateY(-50%);
}

.c-image-gallery__prev {
	left: 0;
}

.c-image-gallery__next {
	right: 0;
}

.c-image-gallery__slides {
	@include list-reset;
}

.c-image-gallery__slide {
	width: 100%;

	&:nth-child(n+2) {
		display: none;

		.flickity-enabled & {
			display: block;
		}
	}
}

.c-image-gallery__caption {
	@include flex-extend;
	align-items: center;
	justify-content: flex-end;
	opacity: 0;

	.c-image-gallery__slide.is-selected & {
		opacity: 1;
	}
}

.c-image-gallery__caption-text {
	flex: auto;
}

.c-image-gallery__caption-count {
	display: none;

	@include bp('medium') {
		@include font-base-12b;
		display: inline;
		padding-left: $lsu;
		white-space: nowrap;
	}
}
