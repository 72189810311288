﻿.t-bg {
	background-color: $primary-a;
}
.t-bg--rev {
	background-color: $primary-b;
}

.t-bg--sec {
	background-color: $secondary-a;
}

.t-bg__text {
	color: $primary-b;
}
.t-text,
.t-bg__text--rev {
	color: $primary-a;
}

.t-bg__text--sec {
	color: $secondary-b;
}

.t-bg--sup-1 {
	background-color: $supporting-1-a;
}
.t-bg__text--sup-1 {
	color: $supporting-1-b;
}

.t-bg__text--rev--active:hover,
.t-bg__text--rev--active:focus,
.t-hover-parent:hover .t-bg__text--rev--active,
.t-hover-parent:focus .t-bg__text--rev--active {
	color: $secondary-a;
}

.t-before-bg::before,
.t-before-bg.is-selected::before {
	background-color: $primary-a;
}

.t-before-text::before {
	color: $primary-a;
}

.t-border-hover:focus,
.t-border-hover:hover,
.t-hover-parent:focus .t-border-hover,
.t-hover-parent:hover .t-border-hover {
	border-color: $primary-a;
}

.t-border-hover[aria-expanded="false"]:hover {
	border-color: $primary-a;
}

.t-link,
.t-link-parent a,
.o-body-text a,
.o-layout-header__lede a,
.ibis-widget-cart .cart-icon {
	color: $link;
}

.t-link:focus,
.t-link:hover,
.t-hover-parent:hover .t-link,
.t-hover-parent:focus .t-link,
.t-link-parent a:focus,
.t-link-parent a:hover,
.o-body-text a:focus,
.o-body-text a:hover,
.o-layout-header__lede a:focus,
.o-layout-header__lede a:hover,
.ibis-widget-cart .cart-icon:focus,
.ibis-widget-cart .cart-icon:hover {
	color: $link-hover;
}

.t-link--primary-hover:focus,
.t-link--primary-hover:hover,
.t-hover-parent:focus .t-link--primary-hover,
.t-hover-parent:hover .t-link--primary-hover {
	color: $primary-a;
}

.t-link-parent--plain a {
	color: $primary-b;
}
.t-link-parent--plain a:hover,
.t-link-parent--plain a:focus {
	text-decoration: underline;
}

.t-link-parent--sec-plain a {
	color: $secondary-b;
}
.t-link-parent--sec-plain a:hover,
.t-link-parent--sec-plain a:focus {
	text-decoration: underline;
}

.c-hero-home__eyebrow::after {
	background-color: $primary-a;
}

.c-accommodation__gallery-nav-item {
	border-color: $primary-a;
}

/* Plugins - we don't want to change the class names */
.flickity-prev-next-button {
	background-color: $primary-a;
}

.flickity-button-icon {
	fill: $primary-b;
}

/***********/
/* Buttons */
/***********/
/* primary */
.o-btn--primary,
.umbraco-forms-form .primary {
	background-color: $primary-a;
	color: $primary-b;
}
.o-btn--primary:hover,
.o-btn--primary:focus,
.umbraco-forms-form .primary:hover,
.umbraco-forms-form .primary:focus,
.t-hover-parent:hover .o-btn--primary,
.t-hover-parent:focus .o-btn--primary {
	background-color: $secondary-a;
	color: $secondary-b;
}

/* outline */
.o-btn--outline {
	background-color: $primary-b;
	color: $primary-a;
	box-shadow: inset 0 0 0 1px $primary-a;
}
.o-btn--outline[aria-current]:not([aria-current="false"]) {
	background-color: $primary-a;
	color: $primary-b;
	box-shadow: inset 0 0 0 1px $primary-a;
}
.o-btn--outline:hover,
.o-btn--outline:focus,
.t-hover-parent:hover .o-btn--outline,
.t-hover-parent:focus .o-btn--outline {
	background-color: $secondary-a;
	color: $secondary-b;
	box-shadow: inset 0 0 0 1px $secondary-a;
}

/* outline-light */
.o-btn--outline-light {
	background-color: $primary-a;
	color: $primary-b;
	box-shadow: inset 0 0 0 1px $primary-b;
}
.o-btn--outline-light[aria-current]:not([aria-current="false"]) {
	background-color: $primary-b;
	color: $primary-a;
	box-shadow: inset 0 0 0 1px $primary-b;
}
.o-btn--outline-light:hover,
.o-btn--outline-light:focus,
.t-hover-parent:hover .o-btn--outline-light,
.t-hover-parent:focus .o-btn--outline-light {
	background-color: $primary-b;
	color: $primary-a;
	box-shadow: inset 0 0 0 1px $primary-b;
}

/* text */
.o-btn--text {
	color: $link;
}
.o-btn--text:hover,
.o-btn--text:focus,
.t-hover-parent:hover .o-btn--text,
.t-hover-parent:focus .o-btn--text {
	color: $link-hover;
}


