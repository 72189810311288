﻿// COMPONENT.SHARE

$share-icon-size: 46px;
$share-icon-font-size: 20px;

$share: #51ae5e;
$facebook: #3b5997;
$twitter: #55acef;
$pinterest: #bd2126;
$google: #d34836;
$weibo: #fa7d3c;

.c-share {
	margin: $lsu auto;
	text-align: center;
}

.c-share--floating {
	transition: left 0.3s ease-in;
	position: fixed;
	left: -#{$share-icon-size};
	top: 200px;
	z-index: $z-share;
	width: $share-icon-size;
	padding: 0;
	margin: 0;

	&.is-visible {
		left: 0;
	}
}

.c-share__heading {
	@include font-base-14;
	display: block;
	margin: 0 0 $ssu;

	.c-share--floating & {
		display: none;
	}
}

.c-share__items {
	@include flex-extend;
	@include flex-wrap;
	justify-content: center;
	margin: -#{$ssu} 0 0 -#{$ssu};
}

.c-share__item {
	padding: $ssu 0 0 $ssu;
}

.c-share__icon {
	@include link-transition;
	@include circle($share-icon-size);
	display: block;
	font-size: $share-icon-font-size;
	text-align: center;
	vertical-align: middle;
}
