// CONTROL.TESTIMONIALS-ROTATOR

.c-testimonials-rotator {
	@include bp-below("medium") {
		.c-testimonials-rotator__item {
			padding-bottom: 25px;
		}
		.flickity-page-dots {
			bottom: 0;
		}
	}
}

.c-testimonials-rotator__viewport {
	@include bp("medium") {
		min-height: 560px;
	}
	overflow: hidden;
	position: relative;
}

.c-testimonials-rotator__prev,
.c-testimonials-rotator__next {
	position: absolute;
	z-index: 1;
	top: 50%;
	transform: translateY(-50%);

	@include bp-below("medium") {
		display: none;
	}
}
.c-testimonials-rotator__prev {
	left: 0;
}
.c-testimonials-rotator__next {
	right: 0;
}

.c-testimonials-rotator__item {
	width: 100%;
	position: relative;
}

.c-testimonials-rotator__figure {
	min-height: 560px;

	@include bp-below("medium") {
		display: none;
	}
}

.c-testimonials-rotator__image {
	min-height: 560px;

	@include bp-below("medium") {
		display: none;
	}
}

.c-testimonials-rotator__spacer {
	min-height: 560px;
	background: $grey--dark;

	@include bp-below("medium") {
		display: none;
	}
}

.c-testimonials-rotator__body {
	@include bp("medium") {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;

		background: rgba($black, 0.2);
		color: $white;
	}
}

.c-testimonials-rotator__body-content {
	@include bp("medium") {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		width: calc(100% - 90px);
		max-width: 900px;
	}

	text-align: center;
	padding: 0 $bsu;
}

.c-testimonials-rotator__abstract {
	display: block;
	margin-top: $lsu;
	@include font-base-14;
	@include bp("medium") {
		@include font-base-20--tall;
	}

	> p:last-child {
		margin-bottom: 0;
	}
}

.c-testimonials-rotator__author {
	display: block;
	margin-top: $lsu;
	@include font-heading-14;
}

.c-testimonials-rotator__btn {
	margin-top: $bsu;
}
