/* OBJECT.LAYOUT */

.o-layout-header__subheading {
	color: $blue--dark;
}

.o-layout-header__heading,
.o-layout-header__heading-text {
	@include font-heading-36;
	color: $blue--dark;

	@include bp("medium") {
		@include font-heading-50;
	}
}

.o-layout-header__heading-text {
	margin-right: 0;

	@include bp("medium") {
		margin-right: $bsu;
	}
}