﻿.c-highlight-text-wrapper {
	&--green {
		.c-highlight-text {
			border-left: 4px solid $highlight-green;
			background-color: rgba($highlight-green, 0.1);
		}

		.o-control__heading:after {
			border-color: $highlight-green;
		}

		.o-body-text a {
			color: $highlight-green !important;
		}
	}

	&--yellow {
		.c-highlight-text {
			border-left: 4px solid $highlight-yellow;
			background-color: rgba($highlight-yellow, 0.1);
		}

		.o-control__heading:after {
			border-color: $highlight-yellow;
		}

		.o-body-text a {
			color: darken($highlight-yellow, 10%) !important;
		}
	}

	&--red {
		.c-highlight-text {
			border-left: 4px solid $highlight-red;
			background-color: rgba($highlight-red, 0.1);
		}

		.o-control__heading:after {
			border-color: $highlight-red;
		}

		.o-body-text a {
			color: $highlight-red !important;
		}
	}

	.c-highlight-text {
		padding: $bsu;

		@include bp('medium') {
			padding: $lsu;
		}
	}
}
