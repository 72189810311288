﻿// THEME

$white: #fff;
$black: #000;

$grey--lightest: #f9f9f9 !default;
$grey--light: #efefef !default;
$grey: #d4d4d4 !default;
$grey--dark: #58595b !default;
$grey--darkest: #1b1b1b !default;

$red: #d52a28 !default;

$purple: #662d91 !default;
$purple--dim: #512076 !default;

$blue: #00aeef !default;
$blue--light: #e5f7fd !default;

$brand: $purple !default;
$brand--alt: $purple--dim !default;

$theme-yellow: #FFCF46;


$highlight-yellow: #FFCF46;
$highlight-green: #32A431;
$highlight-red: #D52A28;

// Shared non-theme colours, e.g. alert, errors
$success: #008000 !default;
$success--light: lighten($success, 40) !default;
$warning: #ffa500 !default;
$warning--light: lighten($warning, 40) !default;
$error: $red !default;
$error--light: lighten($error, 40) !default;

// Assign colours to common variables
$body-color: $grey--darkest;
$base-ui-color: $grey--light;

// Main Colours
$primary-a: $brand !default;
$primary-b: $white !default;
$secondary-a: $brand--alt !default;
$secondary-b: $white !default;

$supporting-1-a: $blue !default;
$supporting-1-b: $white !default;

$supporting-2-a: $blue--light !default;
$supporting-2-b: $black !default;

$link: $brand !default;
$link-hover: $brand--alt !default;
