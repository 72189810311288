// CONTROL.NEWSLETTER

.c-newsletter {
	background: $grey--darkest;
	padding: $bsu;

	.c-form__field-label {
		color: $white;
	}
}
.c-footer {
	.c-newsletter {
		padding: $bsu 0;
	}
}

.c-newsletter__fields {
	@include list-reset;

	@include flex-extend;
	flex-direction: column;
	margin-top: -$bsu;
	margin-left: -$bsu;
	@include bp("small") {
		flex-direction: row;
		flex-wrap: wrap;
	}
	@include bp("medium") {
		flex-wrap: nowrap;
	}
}

.c-newsletter__field,
.c-newsletter__submit {
	flex-grow: 1;

	padding-top: $bsu;
	padding-left: $bsu;

	@include bp("small") {
		width: 50%;
	}
	@include bp("medium") {
		width: auto;
	}
}

.c-newsletter__submit {
	@include flex-extend;
	align-items: flex-end;
}

.c-newsletter__heading {
	color: $white;
}

.c-newsletter__message {
	color: $white;
}
