$contour-sky: #98E2F3;
$contour-bg: #012326;

$contour-peak-height: 92px;

$contour-bp: "medium";

.c-contour {
	position: relative;
	z-index: 1;

	+ .c-hero-home__main .o-control:first-child:not(.c-rte) {
		position: relative;
		z-index: 1;
		margin-top: -($contour-peak-height * 2);

		@include bp("medium") {
			margin-top: -($contour-peak-height * 3);
		}

		.o-control__heading {
			color: $white;
		}
	}
}

.c-contour__head {
}

.c-contour__head-layout {
	@include flex-extend;
	align-items: flex-start;
	justify-content: center;

	@include bp('large') {
		justify-content: space-between;
	}
}

.c-contour__heading {
	display: block;
	//max-width: 750px;
	margin-bottom: $lsu;
	@include bp("medium") {
		margin-right: $lsu;
	}

	@include font-heading-28;
	@include bp("large") {
		@include font-heading-36;
	}

	@supports (border-bottom: var(--primary-a)) {
		position: relative;
		padding-bottom: $bsu;
		&::after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;

			width: 80px;
			border-bottom: 4px solid var(--primary-a);
		}
	}
}

.c-contour__body {
	// Special one-off colour
	background: linear-gradient(to bottom, rgba($blue--dark,0) 0, $blue--dark ($contour-peak-height *2), $blue--dark 100% );


	&::before {
		display: none;
	}

	@include bp-below("medium") {
		background: linear-gradient(to bottom, rgba($blue--dark,0) 0, $blue--dark $contour-peak-height, $blue--dark 100% );
		position: relative;
		padding-top: $lsu;
		padding-bottom: $hsu;
		margin-top: -$contour-peak-height;
	}

	@include bp("medium") {
		padding-top: $lsu;
		padding-bottom: $hsu + $hsu;
		margin-top: - ($contour-peak-height *2);
	}

	color: $white;
	position: relative;

	&::after {
		content: "";
		position: absolute;
		bottom: -148px;
		margin-bottom: -1px;
		left: 0;
		right: 0;
		min-height: 150px;
		pointer-events: none;
		display: block;
		width: 100%;
		background: linear-gradient(to bottom, $blue--dark 0, rgba($blue--dark, 0.8) 50%, rgba($blue--dark, 0) 100%);
	}
}

.c-contour__body-layout {

	@include bp("medium") {
		margin-top: $lsu;
	}
}

.c-contour__body-bottom-layout {
	background-image: url(/content/images/interface/bg/mtc-bg-contour.png);
	background-position: top center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: $bsu $bsu 160px;
	margin-top: $bsu;

	@include bp("medium") {
		margin-top: ($bsu * 3);
	}
}

.c-contour__text {
	@include font-base-14;
	display: block;
	margin-bottom: $lsu;

	@include bp("medium") {
		@include font-base-18;
	}

	@include bp("extra-large") {
		padding-left: $bsu;
	}

	> p:last-child {
		margin-bottom: 0;
	}

	a {
		color: $white;
		text-decoration: underline;
	}
}

.c-contour__explore {
	@include font-base-18b;
	@include flex-extend;
	justify-content: space-between;
}

.c-contour__items {
	@include list-reset;
	@include flex-extend;
	margin-top: $bsu; // $lsu - $bsu
	margin-left: 0;
	margin-bottom: $bsu;
	width: calc(100% + #{$bsu});
	overflow-x: scroll;

	@include bp("large") {
		margin-bottom: $lsu;
		margin-left: -$lsu;
		margin-top: $lsu;
		width: 100%;
		overflow-x: inherit;
	}
}

.c-contour__item {
	padding-top: $bsu;
	padding-left: 0;
	margin-right: $lsu;

	@include bp("medium") {
		padding-top: 0;
	}

	@include bp("large") {
		padding-left: $lsu;
		margin-right: 0;
	}
}

.c-contour__item-link {
	@include flex-extend;
	color: $white;
	justify-content: center;
	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.c-contour__item-image {
	display: block;
	margin-right: $bsu;
	width: 47px;
	flex-grow: 0;
	flex-shrink: 0;
}

.c-contour__item-text {
	@include font-heading-24;
	display: block;
	width: 190px;
	@include bp('large') {
		width: auto;
	}
	&::after {
		content: "";
		display: block;
		background-color: $yellow;
		width: 80px;
		height: 4px;
		margin-top: $ssu;
	}
}
