﻿// CONTROL.PODS


// IMAGE POD
.c-pod__figure {
	position: relative;
	width: 100%;
	height: 100%;
}

.c-pod__body {
	background: linear-gradient(to top, rgba($black, 1) 0, rgba($black, 0) 200px);
	transition: background 0.2s ease-in-out;
}
.c-pod__figure {
	&:hover,
	&:focus {
		.c-pod__body {
			background-color: transparent;
		}
	}
}

.c-pod__text {
	max-height: 0;
	overflow: hidden;
	margin: 0;
	transition: 0.4s ease;
	transition-property: max-height;
}
.c-pod-hover,
.c-pod__figure:focus {
	.c-pod__text {
		max-height: 200px;
	}
}

// NO IMAGE POD
a.c-pod-noimage {
	transition: background-color 0.2s ease-in-out;
}

.c-pod-noimage__body {
	padding: $bsu;
	color: $white;
}
