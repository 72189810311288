﻿// CONTROL.IMAGE-CAROUSEL

.c-image-carousel {
	@include clearfix;
}

.c-image-carousel__items {
	@include list-reset;
	.slick-arrow:before {
		color: $body-color;
	}
}

.c-image-carousel__item {
	display: inline-block;
	width: 100%;
	padding: $bsu;

	&:nth-child(n+2) {
		display: none;

		.flickity-enabled & {
			display: block;
		}
	}

	@include bp('medium') {
		width: 33%;
		float: left;

		&:nth-child(n+2) {
			display: inline-block;
		}

		&:nth-child(n+4) {
			display: none;
		}
	}

	@include bp('large') {
		width: 16.6667%;
		float: left;

		&:nth-child(n+4) {
			display: inline-block;
		}

		&:nth-child(n+7) {
			display: none;
		}
	}
}

.c-image-carousel__slide-link {
	/*@include ui-border('all');
	padding: $lsu;*/
}

.c-image-carousel__items {
	padding: 0;

	@include bp('medium') {
		padding: 0 $lsu;
	}
}

.c-image-carousel__items .flickity-slider {
	@include flex-extend;
	justify-content: center;
	align-items: center;
}

.c-image-carousel__items .flickity-button {
	display: none;

	.is-inactive & {
		display: none;
	}

	@include bp('medium') {
		display: block;
	}
}

.c-image-carousel__items .flickity-page-dots {
	@include bp('medium') {
		display: none;
	}
}