﻿// CONTROL.FORM

.umbraco-forms-label, .c-form__label {
	@include font-base-14;
	display: block;
}

.umbraco-forms-tooltip {
	@include font-base-14;
	color: $grey--dark;
}

.field-validation-error, .c-form__error-message, .validation-summary-errors {
	@include font-base-14;
	color: $error;
}