﻿// MACRO.BLOCKQUOTE

.m-quote__text {
	@include font-20-tall;
	color: $brand;
}

.m-quote__citation {
	color: $brand;
}
