// COMPONENT.ACCOMMODATION-HERO

$experience-hero-bp: "large";

.c-accommodation-hero {
	position: relative;
}

.c-accommodation-hero__title-wrapper {
	@include flex-extend;
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
}

.c-accommodation-hero__title {
	@include font-heading-72;
	text-align: center;
	text-shadow: 0 0 5px $black;
}