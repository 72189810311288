﻿/* UTILITY.WIDTHS */

// Config
//-------
// Columns variable set on _config.scss

$columns: (1, 2, 3, 4, 5) !default;

// Widths
// A mixin to spit out our width classes. Pass in the columns we want the widths
// to have, and an optional suffix for responsive widths.
// E.g. to create thirds and quarters for a small breakpoint:
// @include inuit-widths(3 4, -sm);
@mixin widths($widths-columns, $widths-breakpoint: null) {
	// Loop through the number of columns for each denominator of our fractions.
	@each $widths-denominator in $widths-columns {
		// If we're trying to make wholes, just spit a 100% width utility out
		// one time only.
		@if ($widths-denominator == 1) {
			.u-1\/1#{$widths-breakpoint} {
				width: 100% !important;
			}
		}
		@else {
			// Begin creating a numerator for our fraction up until we hit the denominator.
			@for $widths-numerator from 1 to $widths-denominator {
				// Build a class in the format '.u-3/4'
				.u-#{$widths-numerator}\/#{$widths-denominator}#{$widths-breakpoint} {
					width: calc($widths-numerator / $widths-denominator) * 100% !important;
				}
			}
		}
	}
}

@each $breakpoint in $breakpoints {

	// Get the name of the breakpoint
	// Get the config sass-map
	// Get the suffix
	$alias: nth($breakpoint, 1);
	$config: nth($breakpoint, 2);
	$suffix: map-get($config, "suffix");

	@if ($suffix == "xs") {
		@each $widths-column in $columns {
			@include widths($widths-column);
		}
	}

	@else {
		@include bp($alias) {
			@each $widths-column in $columns {
				@include widths($widths-column, -#{$suffix});
			}
		}
	}
}