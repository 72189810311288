// COMPONENT.ACCOMMODATION

.c-accommodation__content-wrapper {
	@include bp("large"){
		padding-right: ($bsu * 1.5);
	}
}

.c-accommodation__feature {
	padding-top: $lsu;
	margin-bottom: $bsu;
	padding-bottom: $bsu;
	border-top: 2px dashed transparent;
	border-bottom: 2px dashed transparent;

	@supports (border-bottom-color: var(--primary-a)) {
		border-bottom-color: var(--primary-a);
		border-top-color: var(--primary-a);
	}
}

.c-accommodation__feature-items {
	margin-bottom: $bsu;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
}

.c-accommodation__feature-item {
	@include font-base-14;
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: $bsu;

	@include bp("large") {
		width: calc(50% - #{$bsu});
	}

	@include bp("extra-large") {
		width: calc(50% - #{$lsu});
	}
}

.c-accommodation__feature-item-img {
	width: 100%;
	max-width: $lsu;
	margin-right: $bsu;
}

//tariff-panel

.c-tariff-panel {
	margin: 0 auto $lsu;
	max-width: 315px;
	padding: $bsu;
	background-color: $black;
	border-radius: 4px;

	@include bp("medium") {
		flex-shrink: 0;
		width: 315px;
	}
}

.c-tariff-panel__highlight {
	@include font-base-14b;
	margin-bottom: $bsu;
	color: $white;
}

.c-tariff-panel__price-label {
	@include font-base-14;
	margin-bottom: $ssu;
	color: $white;
}

.c-tariff-panel__price {
	margin-bottom: $bsu;
	color: $white;
}

.c-tariff-panel__price-value {
	@include font-heading-36;
}

.c-tariff-panel__price-description {
	@include font-base-14;


	@include bp-below(320px) {
		display: block;
	}
}

.c-tariff-panel__body {
	@include font-base-14;
	padding: ( $ssu * 1.5) 0;
	border-top: 1px dashed $grey;
	color: $white;
}

.c-tariff-panel__booking-widget {
	margin-bottom: $bsu;
}

.c-tariff-panel__booking-btn {
	margin-bottom: -$lsu;
}