$icomoon-font-family: "font-icons" !default;
$icomoon-font-path: "fonts" !default;

$iconf-cart: "\1f6d2";
$iconf-video: "\1f4f9";
$iconf-hamburger: "\e901";
$iconf-phone: "\1f4de";
$iconf-email: "\2709";
$iconf-minus: "\2d";
$iconf-plus: "\2b";
$iconf-arrow-down: "\2193";
$iconf-arrow-left: "\2190";
$iconf-arrow-right: "\2192";
$iconf-arrow-up: "\2191";
$iconf-calendar: "\1f4c5";
$iconf-chat: "\1f5e9";
$iconf-close: "\2718";
$iconf-delete: "\1f5d1";
$iconf-address: "\1f4cd";
$iconf-download: "\e90f";
$iconf-external: "\e910";
$iconf-facebook: "\e911";
$iconf-instagram: "\e912";
$iconf-link: "\1f517";
$iconf-linkedin: "\e914";
$iconf-alert: "\26a0";
$iconf-photo: "\1f5bc";
$iconf-pinterest: "\e917";
$iconf-play: "\25b6";
$iconf-print: "\1f5a8";
$iconf-reload: "\1f5d8";
$iconf-search: "\1f50d";
$iconf-tick: "\2714";
$iconf-twitter: "\e91d";
$iconf-web: "\e91e";
$iconf-wechat: "\e91f";
$iconf-weibo: "\e920";
$iconf-youku: "\e921";
$iconf-youtube-play: "\e922";
$iconf-youtube: "\e923";

