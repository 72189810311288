﻿// CONTROL.CTA

.c-cta__body {
	position: relative;
	padding: $bsu;
	text-align: center;

	box-shadow: 0 3px 10px 2px rgba($black, 0.2);

	> *:last-child {
		margin: 0;
	}

	@include bp('medium') {

		.o-layout-main & {
			@include flex-extend;
			padding: ($bsu * 1.5) ($bsu * 2);
			align-items: center;
			text-align: left;
		}
	}

	@include bp('large') {

		.o-layout-main & {
			@include flex-extend;
			padding: ($bsu * 1.5) ($bsu * 4);
			align-items: center;
			text-align: left;
		}
	}
}

.c-cta__body-wrapper {
	margin-bottom: $bsu;

	@include bp('medium') {

		.o-layout-main & {
			flex: 1 1 auto;
			padding-right: $lsu;
			margin-bottom: 0;
		}
	}

	> *:last-child {
		margin-bottom: 0;
	}
}

.c-cta__heading {
	margin-bottom: 0.5em;

	@include font-heading-28;
	@include bp('large') {
		@include font-heading-36;
	}

	@supports (border-bottom: var(--primary-a)) {
		position: relative;
		padding-bottom: $bsu;
		&::after {
			content: "";
			position: absolute;
			right: 50%;
			bottom: 0;
			transform: translateX(50%);

			width: 80px;
			border-bottom: 4px solid var(--primary-a);
		}

		.o-layout-main & {
			@include bp("medium") {
				&::after {
					right: auto;
					left: 0;
					transform: none;
				}
			}
		}
	}
}

.c-cta__text {
	@include font-body-text;
	margin-bottom: 2em;
	color: $body-color;
}

.c-cta__link {

	@include bp('medium') {
		white-space: nowrap;
	}
}