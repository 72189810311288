// CONTROL.ACCOMMODATION-CAROUSEL

.c-accommodation-carousel {
	padding: $bsu 0 $lsu;
	background-color: $grey--lightest;
	
	&.c-accommodation--dark {
		background-color: $black;
	}
}

.c-accommodation-carousel__heading {
	text-align: center;

	.c-accommodation--dark &{
		color: $white;
	}
}

.c-accommodation-carousel__viewport {
	position: relative;

	&[data-button="false"] {
		.c-accommodation-carousel__prev,
		.c-accommodation-carousel__next {
			@include bp("extra-large") {
				display: none;
			}
		}
	}
}

.c-accommodation-carousel__btn-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: $bsu 0 $lsu;

	@include bp("medium") {
		margin: $bsu $bsu $lsu;
	}

	&[data-s-hide="true"] {
		.c-accommodation-carousel__prev,
		.c-accommodation-carousel__next {
			display: none;
		}
	}

	@include bp("small") {
		&[data-s-hide="true"] {
			.c-accommodation-carousel__prev,
			.c-accommodation-carousel__next {
				display: none;
			}
		}
	}

	@include bp("medium") {
		&[data-m-hide="true"] {
			.c-accommodation-carousel__prev,
			.c-accommodation-carousel__next {
				display: none;
			}
		}
	}

	@include bp("large") {
		&[data-l-hide="true"] {
			.c-accommodation-carousel__prev,
			.c-accommodation-carousel__next {
				display: none;
			}
		}
	}

	@include bp("extra-large") {
		&[data-lx-hide="true"] {
			.c-accommodation-carousel__prev,
			.c-accommodation-carousel__next {
				display: none;
			}
		}
	}

	.c-accommodation__details & {
		&[data-s-hide="true"] {
			display: none;
		}

		@include bp("small") {
			&[data-s-hide="true"] {
				display: none;
			}
		}

		@include bp("medium") {
			&[data-m-hide="true"] {
				display: none;
			}
		}

		@include bp("large") {
			&[data-l-hide="true"] {
				display: none;
			}
		}

		@include bp("extra-large") {
			&[data-lx-hide="true"] {
				display: none;
			}
		}
	}
}

.c-accommodation-carousel__prev,
.c-accommodation-carousel__next {
	position: static;
	transform: translateY(0);
	margin: 0 $bsu;
}

.c-accommodation-carousel__list {
	@include flex-extend;
	flex-wrap: wrap;
}

.c-accommodation-carousel__item {
	padding: 0 $ssu;
	width: 90%;

	@include bp("small") {
		width: percentage(calc(1/2));
	}

	@include bp("medium") {
		width: percentage(calc(1/2));
	}

	@include bp("large") {
		width: percentage(calc(1/3));
	}

	@include bp("extra-large") {
		width: percentage(calc(1/4));

		.o-layout-content--has-aside & {
			width: percentage(calc(1/3));
		}
	}
}

.c-accommodation-carousel__all {
	@include flex-extend;
	@include font-heading-16;
	justify-content: center;
}

.c-accommodation-carousel__list.flickity-enabled {
	display: block;

	@include bp("medium") {
		margin: $lsu $lsu 0;

		.flickity-prev-next-button {
			&.previous {
				left: auto;
				right: 100%;
			}

			&.next {
				right: auto;
				left: 100%;
			}
		}
	}

	.flickity-viewport {
		&[style*="height"] {
			.c-accommodation-carousel__item {
				height: 100%;
			}
		}
	}
}

//ACCOMMODATION-GALLERY
.c-accommodation__gallery {
	margin-bottom: $bsu;
}

.c-accommodation__gallery-item {
	width: 100%;
}

.c-accommodation__gallery-nav {
	margin-bottom: 0;
}

.c-accommodation__gallery-nav-item {
	width: 40%;
	opacity: 1;
	margin: 0 $tsu;

	@include bp('large') {
		width: 20%;
	}

	&:hover,
	&.is-nav-selected {
		opacity: 0.6;
		border: 2px solid transparent;

		@supports (border-color: var(--primary-a)) {
			border-color: var(--primary-a);
		}
	}
}