// COMPONENT.FOOTER

$footer-bp--mobile: "medium";
$footer-bp: "extra-large";

$footer-logo-width: 150px;



.c-footer__secondary {
	background: $white;
}

.c-footer__primary-head {
	@include font-base-12b;
}

.c-footer__social-subheading {
	@include font-base-12b;
}

.c-footer__social-heading {
	@include font-base-20b;

	@include bp('medium') {
		@include font-base-24b;
	}
}

.c-footer__navigation-link {
	@include font-base-12b;
}