// OBJECT.CONTROL

.o-control__heading {
	color: $blue--dark;

	.c-image-cta__body & {
		color: $white;
	} 

	&.c-transfer-widget__heading {
		@supports (color: var(--primary-b)) {
			color: var(--primary-b);
		}
	}
}

.o-layout-aside .o-control__heading {
	color: $blue--dark;
}


.o-control__heading {
	@include font-heading-28;

	@include bp('large') {
		@include font-heading-36;
	}
}

.o-layout-aside .o-control__heading {
	@include font-heading-20;

	@include bp('large') {
		@include font-heading-24;
	}
}