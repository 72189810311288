// COMPONENT.NAVIGATION

$megamenu-bp: "large";


// Main Navigation
$navigation-bg: $white;

.c-navigation {
	display: none;
	width: 100%;
	background-color: $navigation-bg;

	@include bp('large') {
		display: block;
		align-self: flex-end;
		width: auto;
		background-color: transparent;
	}

	&[aria-expanded="true"] {
		display: block;
		position: absolute;
		top: $header-height;
		left: 0;
		height: calc(100vh - #{$header-height});
	}
}

.c-navigation__items {
	height: $header-height;

	@include flex-extend;
	align-items: stretch;
}

.c-navigation__item {
	@include flex-extend;
	align-items: stretch;

	&[aria-expanded="true"],
	&[aria-current]:not([aria-current="false"]) {
		.c-navigation__link {
			color: var(--primary-a);

			&::after {
				content: "";
				position: absolute;
				bottom: 0;
				left: $bsu;
				right: $bsu;

				border-bottom: 5px solid $grey;
				border-bottom-color: var(--primary-a);
			}
		}
	}
}

.c-navigation__link {
	@include flex-extend;
	align-items: center;

	@include font-heading-14;
	color: $body-color;

	padding: $bsu $ssu;
	@include bp("extra-large") {
		padding: $bsu;
	}
	white-space: nowrap;

	position: relative;
}

.c-navigation__link--home {
	font-size: rem(20px);

	@include bp('large') {
		line-height: 1;
	}
}

//////////////
// Megamenu //
//////////////
.c-navigation__megamenu {
	display: none;

	position: absolute;
	overflow: hidden;
	left: 0;
	right: 0;
	top: 100%;

	padding: 0 $hsu;

	background: $white;
	box-shadow: 0 20px 20px -20px rgba($black, 0.2);
	border-top: 1px solid $grey;

	text-align: left;
}
@include bp($megamenu-bp) {
	.c-navigation__item[aria-expanded="true"] {
		.c-navigation__megamenu {
			display: block;
		}
	}
}

.c-navigation__megamenu-layout {
	@include flex-extend;


}


.c-navigation__megamenu-abstract {
	width: percentage(calc(1/4));
	padding: $lsu $bsu;
	padding-left: 0;
}

.c-navigation__megamenu-abstract-heading {
	display: block;
	margin-bottom: $bsu;

	@include font-heading-24;
}

.c-navigation__megamenu-abstract-text {
	display: block;
	margin-bottom: $bsu;

	@include font-body-text;
}

.c-navigation__megamenu-abstract-link {}


.c-navigation__megamenu-groups {
	@include list-reset;
	width: percentage(calc(1/2));

	@include flex-extend;

	.c-navigation__megamenu-section {
		width: percentage(calc(1/2));

		&:first-child:last-child {
			width: 100%;

			.c-navigation__megamenu-section-links {
				margin-top: 36px;

				@include flex-extend;
				flex-wrap: wrap;
				align-items: flex-start;
			}

			.c-navigation__megamenu-section-item {
				width: percentage(calc(1/2));
				padding-right: $bsu;
			}
		}
	}
}


.c-navigation__megamenu-quicklinks {
	width: percentage(calc(1/4));
	padding: $lsu $bsu;
	padding-right: 0;
	background: $grey--lightest;
	position: relative;

	&::after {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: calc(100% - 1px);
		width: 10vw;
		background: $grey--lightest;
	}
}


.c-navigation__megamenu-section {
	padding: $lsu $bsu;
}

.c-navigation__megamenu-section-heading {
	margin-top: 12px; // Align with abstract heading
	display: block;
	margin-bottom: 24px; // Align with abstract text

	@include font-base-12-caps;
}

.c-navigation__megamenu-section-links {
	@include list-reset;

	margin-top: 50px;
}
.c-navigation__megamenu-section-heading + .c-navigation__megamenu-section-links {
	margin-top: 0;
}

.c-navigation__megamenu-section-item {
	margin: 15px 0;
	&:last-child {
		margin-bottom: 0;
	}
}

.c-navigation__megamenu-section-link {
	@include font-heading-16;
}
