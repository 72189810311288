// COMPONENT.HERO

.c-hero {
	position: relative;
}

.c-hero__heading {
	@include font-36b;
	position: absolute;
	top: calc(50% + 32px);
	left: 50%;
	transform: translate(-50%, -50%);
	color: $white;
	text-shadow: 0 0 5px $black;
}

/////////////
// PRIMARY //
/////////////

.c-hero__primary {
	margin: 0 0 $bsu 0;
	position: relative;
	z-index: 1;

	@include bp("large") {
		@include flex-extend;
		margin: $bsu 0 $hsu 0;
		align-items: stretch;
	}

	&.c-hero__primary--right {
		@include bp("large") {
			flex-direction: row-reverse;
		}
	}
}

.c-hero__body {
	@include flex-extend;
	flex-direction: column;
	overflow: visible;
	padding: $bsu 0;

	@include bp("large") {
		padding: ($lsu * 3) $bsu 0;
		width: 350px;
	}


	@include bp("extra-large") {
		width: 450px;
	}
}

.c-hero__title {
	@include font-heading-50;
	color: $brand;
	position: relative;
	z-index: 2;
	margin-bottom: $ssu;

	@include bp("medium") {
		margin-bottom: $bsu;
	}

	@include bp("large") {
		font-size: 85px;
		line-height: 1;
		margin-bottom: $lsu;
	}
}

.c-hero__text {
	@include font-base-18;
	display: none;

	@include bp("large") {
		display: block;
		flex-grow: 1;
	}
}

.c-hero__scroll-icon {
	margin-top: $lsu;
	color: $brand;
	text-align: center;
	font-size: 24px;
	display: none;

	@include bp("large") {
		display: block;
	}
}

.c-hero__figure {
	flex-grow: 1;
	position: relative;
	overflow: hidden;

	@include bp("large") {
		margin-left: $lsu;
	}

	.c-hero__primary--right & {
		@include bp("large") {
			margin-right: $lsu;
			margin-left: 0;
		}
	}
}

.c-hero__image {
	display: block;
	width: 100%;

	@include bp("large") {
		height: 100%;
		object-fit: cover;
	}
}

///////////////
// SECONDARY //
///////////////
.c-hero__secondary {
	margin: $lsu 0;

	@include bp("large") {
		@include flex-extend;
		margin: $hsu 0;
	}
}

.c-hero__secondary-title {
}

.c-hero__secondary-title-text {
	color: $brand;
	margin-bottom: $bsu;

	@include bp("large") {
		padding: 0 20px;
		width: 350px;
		margin-bottom: 0;
	}

	@include bp("extra-large") {
		width: 450px;
	}
}

.c-hero__secondary-introduction {
	@include font-base-18;
	flex: 0 1 auto;

	@include bp("large") {
		margin-top: 4px; // Align better with secondary title
		margin-left: 40px;
	}
}