$base-font-family: "Oxygen", "Open Sans", Arial, Helvetica, sans-serif;
$heading-font-family: "Permanent Marker", Arial, Helvetica, sans-serif; 
	
@mixin font-base-24 {
	font-family: $base-font-family;
	font-size: 24px;
	font-weight: $weight--normal;
	line-height: lh(28, 24);
}

@mixin font-base-24b {
	@include font-base-24;
	font-weight: $weight--bold;
}

@mixin font-base-20 {
	font-family: $base-font-family;
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: lh(28, 20);
}

@mixin font-base-20b {
	@include font-base-20;
	font-weight: $weight--bold;
}

@mixin font-base-18 {
	font-family: $base-font-family;
	font-size: 18px;
	font-weight: $weight--normal;
	line-height: lh(26, 18);
}

@mixin font-base-18b {
	@include font-base-18;
	font-weight: $weight--bold;
}

@mixin font-base-16 {
	font-family: $base-font-family;
	font-size: 16px;
	font-weight: $weight--normal;
	line-height: lh(18, 16);
}

@mixin font-base-16b {
	@include font-base-16;
	font-weight: $weight--bold;
}

@mixin font-base-15 {
	font-family: $base-font-family;
	font-size: 15px;
	font-weight: $weight--normal;
	line-height: lh(26, 15);
}

@mixin font-base-14 {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--normal;
	line-height: lh(18, 14);
}

@mixin font-base-14b--caps {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--bold;
	line-height: lh(18, 14);
	text-transform: uppercase;
}

@mixin font-base-14--caps {
	font-family: $base-font-family;
	font-size: 14px;
	font-weight: $weight--normal;
	line-height: lh(22, 14);
	text-transform: uppercase;
}

@mixin font-base-13b {
	font-family: $base-font-family;
	font-size: 13px;
	font-weight: $weight--bold;
	line-height: lh(15, 13);
}

@mixin font-base-13b--caps {
	@include font-base-13b;
	text-transform: uppercase;
}

@mixin font-base-13--caps {
	font-family: $base-font-family;
	font-size: 13px;
	font-weight: $weight--normal;
	line-height: lh(15, 13);
	text-transform: uppercase;
}

@mixin font-base-12 {
	font-family: $base-font-family;
	font-size: 12px;
	font-weight: $weight--normal;
	line-height: lh(14, 12);
}

@mixin font-base-10--caps {
	font-family: $base-font-family;
	font-size: 10px;
	font-weight: $weight--normal;
	line-height: lh(12, 10);
	text-transform: uppercase;
}

@mixin font-heading-100 {
	font-family: $heading-font-family;
	font-size: 100px;
	font-weight: $weight--normal;
	line-height: lh(100, 100);
}

@mixin font-heading-72 {
	font-family: $heading-font-family;
	font-size: 72px;
	font-weight: $weight--normal;
	line-height: lh(72, 72);
}

@mixin font-heading-50 {
	font-family: $heading-font-family;
	font-size: 50px;
	font-weight: $weight--normal;
	line-height: lh(58, 50);
}

@mixin font-heading-36 {
	font-family: $heading-font-family;
	font-size: 36px;
	font-weight: $weight--normal;
	line-height: lh(42, 36);
}

@mixin font-heading-28 {
	font-family: $heading-font-family;
	font-size: 28px;
	font-weight: $weight--normal;
	line-height: lh(36, 28);
}

@mixin font-heading-24 {
	font-family: $heading-font-family;
	font-size: 24px;
	font-weight: $weight--normal;
	line-height: lh(28, 24);
}

@mixin font-heading-20 {
	font-family: $heading-font-family;
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: lh(28, 20);
}

@mixin font-heading-18 {
	font-family: $heading-font-family;
	font-size: 18px;
	font-weight: $weight--normal;
	line-height: lh(20, 18);
}

@mixin font-heading-16 {
	font-family: $heading-font-family;
	font-size: 16px;
	font-weight: $weight--normal;
	line-height: lh(18, 16);
}

@mixin font-heading-15 {
	font-family: $heading-font-family;
	font-size: 15px;
	font-weight: $weight--normal;
	line-height: lh(18, 15);
}

@mixin font-heading-14-caps {
	font-family: $heading-font-family;
	font-size: 14px;
	font-weight: $weight--normal;
	line-height: lh(18, 14);
	text-transform: uppercase;
}

@mixin font-heading-13 {
	font-family: $heading-font-family;
	font-size: 13px;
	font-weight: $weight--normal;
	line-height: lh(15, 13);
}

@mixin font-heading-13--caps {
	@include font-heading-13;
	text-transform: uppercase;
}

@mixin font-heading-13b {
	font-family: $heading-font-family;
	font-size: 13px;
	font-weight: $weight--bold;
	line-height: lh(15, 13);
}

@mixin font-heading-13b--caps {
	font-family: $heading-font-family;
	font-size: 13px;
	font-weight: $weight--bold;
	line-height: lh(15, 13);
	text-transform: uppercase;
}

html {
	color: $body-color;
}
