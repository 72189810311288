﻿/*
https://ibishelp.kayako.com/Knowledgebase/Article/View/configure-your-e-commerce-website-within-an-iframe
---------------------------------------------- */

.ibis-iframe {
	border: none;
	width: 100%;
}

.ibis-widget-cart {
	.fit-cart-widget {
		@extend .c-header__tool;
		@extend .c-header__tool--button;
		position: relative;

		&.has-item {
			cursor: pointer;
		}
	}

	.cart-icon {
		&::before {
			@include font-icon;
			content: $iconf-cart;
			font-size: 16px;
		}
	}

	.cart-widget-badge {
		position: absolute;
		top: 0;
		right: 0;

		background: $grey;
		@include font-12b;
		width: 15px;
		height: 15px;
		border-radius: 100%;

		text-align: center;
		line-height: 17px;
	}
}
