﻿// COMPONENT.BOOKING-WIDGET

.c-booking-widget {
	margin-top: $bsu;
	padding-top: $bsu;

	@include bp("medium") {
		margin-bottom: $lsu;
		+ .o-control {
			margin-top: $lsu;
		}
	}
}

.c-booking-form__mobile-toggle {
	background-color: $black;
	text-align: center;
	padding: $ssu $bsu;
}

.c-booking-form__mobile-toggle-text {
	@include font-heading-18;
	color: $white;
}

.c-booking-form__container {
	@include bp("large") {
		margin-bottom: $lsu;
	}
}

.c-booking-form__wrapper {
	height: 0;
	overflow: hidden;
	background-color: $black;
	padding: ($lsu* 1.5) $bsu 0;
	display: none;
	@include link-transition;

	@include bp("large") {
		display: block;
		position: absolute;
		width: 100%;
		height: auto;
		overflow: inherit;
		z-index: 1;
		margin-top: -$hsu;
		background-color: transparent;
		padding: 0;
		top: inherit;
		left: inherit;
	}

	&[aria-expanded="true"] {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		visibility: visible;
		z-index: 2;

		@include bp("large") {
			display: block;
			position: absolute;
			width: 100%;
			height: auto;
			overflow: inherit;
			z-index: 1;
			margin-top: -$hsu;
			background-color: transparent;
			padding: 0;
			top: inherit;
			left: inherit;
		}
	}
}

.c-booking-form-close__wrapper {
	display: flex;
	justify-content: flex-end;
	padding: 0 $bsu;

	@include bp("large") {
		display: none;
	}
}

.c-booking-form {
	padding: ($bsu * 1.5) $bsu;
	background: $black;
	border-radius: 3px;
	
	@include bp("medium") {
		padding: ($bsu * 1.5) ($lsu * 1.5);
	}
}

.c-booking-form .form-group {
	margin-bottom: $bsu;

	@include bp("large") {
		margin-bottom: 0;
	}
}

.c-booking-form .c-form__input-group {
	position: relative;
}

.c-booking-form__icon {
	position: absolute;
	top: ($ssu * 1.5);
	right: ($ssu * 1.5);
}

.form-control.hasDatepicker {
	.c-booking-form .c-form__input-group & {
		padding-right: $lsu;
		cursor: pointer;
	}
}

.c-booking-form .c-form__input-group {
	&::after {
		@include font-icon;
		content: $iconf-calendar;
		position: absolute;
		display: block;
		right: 10px;
		top: 15px;
		pointer-events: none;
	}
}

.c-booking-form__label {
	@include font-base-14--tall;
	color: $white;
	margin-bottom: 13px;
	display: block;
}

.c-booking-form__checkbox-label__wrapper {
	@include font-base-14--tall;
	color: $white;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: ($ssu * 1.5);
	line-height: 1;

	@include bp("large") {
		justify-content: center;
	}
}

.c-booking-form__checkbox-label {
	margin-left: $ssu;
	margin-bottom: 0;
	line-height: 1;
}

.c-booking-form__btn.o-btn {
	padding: 15px 23px;
}