﻿.c-juicer {
	// Create stacking context
	position: relative;
	z-index: 1;
}

.c-juicer .o-body-text {
	margin-bottom: $bsu;
}

.juicer-feed h1.referral a {
	display: none !important;
}
.juicer-feed.slider .slick-prev::before, .juicer-feed.slider .slick-next::before{
	font-size: 2rem !important;
}