// COMPONENT.EXPERIENCE-HERO

$experience-hero-bp: "large";

.c-experience-hero {
	@include flex-extend;
	align-items: stretch;
	position: relative;
	z-index: 1;
	min-height: 200px;

	@include bp("medium") {
		height: 50vh;
		min-height: 400px;
	}

	max-height: 80vh;

	&::after {
		content: "";
		position: absolute;
		z-index: 1;
		bottom: 0;
		left: 0;
		right: 0;
		padding-top: percentage(calc(720 / 1887));
		background: url(/content/images/interface/bg/mtc-bg-hero.svg) no-repeat top center;
		background-size: cover;
	}

	&::before {
		content: "";
		position: absolute;
		top: inherit;
		margin-bottom: -1px;
		left: 0;
		right: 0;
		background: linear-gradient(to bottom, rgba($white, 0) 0%, $white 70%, $white 100%);
	}
}