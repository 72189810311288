﻿/* UTILITY.ICONS */

// .iconf-* usage example
//-----------------------
/*
	<span class="iconf-facebook" aria-hidden="true"></span>
*/

@font-face {
	font-family: 'font-icons';
	src: url("/content/fonts/mtc-font-icons/fonts/font-icons.woff2") format("woff2"), url("/content/fonts/mtc-font-icons/fonts/font-icons.ttf?tnfnl1") format("truetype"), url("/content/fonts/mtc-font-icons/fonts/font-icons.woff?tnfnl1") format("woff"), url("/content/fonts/mtc-font-icons/fonts/font-icons.svg?tnfnl1#font-icons") format("svg");
	font-weight: normal;
	font-style: normal;
}

// Chrome rendering bugfix - http://www.adtrak.co.uk/blog/font-face-chrome-rendering/
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: 'font-icons';
		src: url('/content/fonts/mtc-font-icons/fonts/font-icons.svg#font-icons') format('svg');
	}
}

// Use !important to prevent issues with browser extensions that change fonts
[data-iconf]:before,
[class*="iconf-"],
%iconf {
	font-family: 'font-icons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	// Better Font Rendering
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[data-iconf]:before {
	content: attr(data-iconf);
}

// Paste list of icon classes from Icomoon below this line

.iconf-cart {
	&:before {
		content: $iconf-cart;
	}
}

.iconf-video {
	&:before {
		content: $iconf-video;
	}
}

.iconf-hamburger {
	&:before {
		content: $iconf-hamburger;
	}
}

.iconf-phone {
	&:before {
		content: $iconf-phone;
	}
}

.iconf-email {
	&:before {
		content: $iconf-email;
	}
}

.iconf-minus {
	&:before {
		content: $iconf-minus;
	}
}

.iconf-plus {
	&:before {
		content: $iconf-plus;
	}
}

.iconf-arrow-down {
	&:before {
		content: $iconf-arrow-down;
	}
}

.iconf-arrow-left {
	&:before {
		content: $iconf-arrow-left;
	}
}

.iconf-arrow-right {
	&:before {
		content: $iconf-arrow-right;
	}
}

.iconf-arrow-up {
	&:before {
		content: $iconf-arrow-up;
	}
}

.iconf-calendar {
	&:before {
		content: $iconf-calendar;
	}
}

.iconf-chat {
	&:before {
		content: $iconf-chat;
	}
}

.iconf-close {
	&:before {
		content: $iconf-close;
	}
}

.iconf-delete {
	&:before {
		content: $iconf-delete;
	}
}

.iconf-address {
	&:before {
		content: $iconf-address;
	}
}

.iconf-download {
	&:before {
		content: $iconf-download;
	}
}

.iconf-external {
	&:before {
		content: $iconf-external;
	}
}

.iconf-facebook {
	&:before {
		content: $iconf-facebook;
	}
}

.iconf-instagram {
	&:before {
		content: $iconf-instagram;
	}
}

.iconf-link {
	&:before {
		content: $iconf-link;
	}
}

.iconf-linkedin {
	&:before {
		content: $iconf-linkedin;
	}
}

.iconf-alert {
	&:before {
		content: $iconf-alert;
	}
}

.iconf-photo {
	&:before {
		content: $iconf-photo;
	}
}

.iconf-pinterest {
	&:before {
		content: $iconf-pinterest;
	}
}

.iconf-play {
	&:before {
		content: $iconf-play;
	}
}

.iconf-print {
	&:before {
		content: $iconf-print;
	}
}

.iconf-reload {
	&:before {
		content: $iconf-reload;
	}
}

.iconf-search {
	&:before {
		content: $iconf-search;
	}
}

.iconf-tick {
	&:before {
		content: $iconf-tick;
	}
}

.iconf-twitter {
	&:before {
		content: $iconf-twitter;
	}
}

.iconf-web {
	&:before {
		content: $iconf-web;
	}
}

.iconf-wechat {
	&:before {
		content: $iconf-wechat;
	}
}

.iconf-weibo {
	&:before {
		content: $iconf-weibo;
	}
}

.iconf-youku {
	&:before {
		content: $iconf-youku;
	}
}

.iconf-youtube-play {
	&:before {
		content: $iconf-youtube-play;
	}
}

.iconf-youtube {
	&:before {
		content: $iconf-youtube;
	}
}

