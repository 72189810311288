// COMPONENT.HAMNAV
.c-hamnav__wrapper {
	position: absolute;
	z-index: 2;
	top: #{$header-height};
	right: 0;

	overflow: hidden;

	@include bp-below("medium") {
		margin: 0;
		padding: 0;

		width: 100%;
		height: calc(100vh - #{$header-height});
	}
	@include bp("medium") {
		margin: -5px 0 -5px -5px;
		padding: 5px 0 5px 5px;

		width: 380px;
		max-height: calc(100vh - #{$header-height});
	}

	visibility: hidden;
	&[aria-expanded="true"] {
		animation: 0.5s becomeVisible forwards;
		.c-hamnav {
			animation: 0.5s slideinRight forwards,
			           0.5s fadein forwards,
			           0.5s becomeVisible forwards;
		}
	}
	&[aria-expanded="false"] {
		animation: 0.5s becomeHidden forwards;
		.c-hamnav {
			animation: 0.5s slideoutRight forwards,
			           0.5s fadeout forwards,
			           0.5s becomeHidden forwards;
		}
	}
}

.c-hamnav {
	width: 100%;
	height: 100%;
	max-height: calc(100vh - #{$header-height});

	visibility: hidden;
	background: $white;
	box-shadow: 0 0 5px 0 rgba($black, 0.2);

	overflow: auto;
}

.c-hamnav__sections {
	@include list-reset;

	padding: $bsu;
	@include bp("medium") {
		padding-top: $lsu;
	}
}

.c-hamnav__section {
	margin: $bsu 0;
}

.c-hamnav__section--has-children {
	.c-hamnav__section-link {
		&::before {
			@include font-icon;
			content: $iconf-plus;

			position: absolute;
			left: 0;
			top: 5px;

			font-size: 12px;
		}
	}
}

.c-hamnav__section-link {
	display: inline-block;

	@include font-base-18b;

	&.t-link {
		@supports (color: var(--link--hover)) {
			color: var(--link--hover)
		}

		&:hover {
			@supports (color: var(--link)) {
				color: var(--link)
			}
		}

		&::before {
			@supports (color: var(--link)) {
				color: var(--link)
			}
		}
	}
}

.c-hamnav__children {
	@include list-reset;

	display: none;
}
.c-hamnav__section[aria-expanded="true"] {
	.c-hamnav__section-link {
		&::before {
			content: $iconf-minus;
		}
	}

	.c-hamnav__children {
		display: block;
	}
}


.c-hamnav__child-link {
	@include font-base-14;
}

