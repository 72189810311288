// OBJECT.BODY-TEXT

// First class here targets the RTE within the CMS so it will also get styled
.mce-content-body {
	padding: 10px !important;
}

.mce-content-body,
.o-body-text {
	@include clearfix;

	& + & {
		margin-top: $bsu;
	}

	> *:first-child {
		margin-top: 0;
	}

	> *:last-child {
		margin-bottom: 0;
	}

	h2,
	h3,
	h4,
	h5 {
		margin: 1.2em 0 0.6em;
	}

	h2 {
		@include font-heading-28;
	}

	h3 {
		@include font-heading-24;
	}

	h4 {
		@include font-heading-20;
	}

	h5 {
		@include font-heading-18;
	}

	> p {
		@include font-body-text;
		margin-bottom: 1.5em;
	}

	hr {
		border-width: 1px 0 0;
		border-top: 1px solid $base-ui-color;
		height: 0;
		clear: both;
		margin: 1.5em 0;
	}

	> ul,
	> ol {
		padding: 0 0 0 1em;
		margin: 1.5em 0;

		li {
			@include font-body-text;
			margin-bottom: 1em;
		}

		ul,
		ol {
			margin: 0.25em 0;
		}

		ul {

			li {
				list-style-type: circle;
			}
		}

		ol {

			li {
				list-style-type: lower-roman;
			}
		}
	}

	> ul > li {
		list-style-type: disc;
	}

	> ol > li {
		list-style-type: decimal;
	}

	// When a list style is selected in the RTE, make sure it is applied
	ul[style*="list-style-type: circle;"] {
		> li { list-style-type: circle; }
	}
	ul[style*="list-style-type: disc;"] {
		> li { list-style-type: disc; }
	}
	ul[style*="list-style-type: square;"] {
		> li { list-style-type: square; }
	}

	ol[style*="list-style-type: lower-alpha;"] {
		> li { list-style-type: lower-alpha; }
	}
	ol[style*="list-style-type: lower-greek;"] {
		> li { list-style-type: lower-greek; }
	}
	ol[style*="list-style-type: lower-roman;"] {
		> li { list-style-type: lower-roman; }
	}
	ol[style*="list-style-type: upper-alpha;"] {
		> li { list-style-type: upper-alpha; }
	}
	ol[style*="list-style-type: upper-roman;"] {
		> li { list-style-type: upper-roman; }
	}

	table {
		width: 100% !important;
		min-width: 480px;
		clear: both;
		margin-bottom: $bsu;
		border-collapse: collapse;

		p {
			margin: 0;
		}
		// Remove default min-width if content editor has set the width in the CMS
		&[style*="width"] {
			min-width: 0;
		}
		// Add margin to right if content editor has set the table to float left in the CMS
		&[style*="float: left"] {
			margin-right: $bsu;
		}
		// Add margin to left if content editor has set the table to float right in the CMS
		&[style*="float: right"] {
			margin-left: $bsu;
		}
	}

	.tablescroll table {
		margin-bottom: 0;
	}

	th,
	thead td {
		text-align: left;
		@include font-base-14sb;
		background-color: $grey--lightest;

		background-color: var(--supporting-1-a);
		color: var(--supporting-1-b);
	}

	th,
	td {
		padding: $ssu $bsu;
	}

	tbody {
		tr:nth-child(2n) {
			background: var(--supporting-2-a);
			color: var(--supporting-2-b);
		}

		td {
			text-align: left;
		}
	}

	td {
		@include ui-border('all');

		@include font-base-14;
		vertical-align: top;
	}

	caption {
		@include ui-border(bottom);
		@include font-base-12;
		caption-side: bottom;
		padding: $bsu 0;
		text-align: left;
	}
}

// RTE figure - shared styling across macros
.o-body-text__figure {
	margin: $lsu auto;
	clear: both;

	&.u-float--left {
		clear: left;

		@include bp('small') {
			margin: 0 $lsu $bsu 0;
		}
	}

	&.u-float--right {
		clear: right;

		@include bp('small') {
			margin: 0 0 $bsu $lsu;
		}
	}
}

.o-body-text__figcaption {
	@include ui-border('bottom');
	@include font-base-12;
	padding: $bsu 0
}
