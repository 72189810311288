// COMPONENT.INFO-WIDGET

$weather-width-single: 280px;


.c-info-widget__wrapper {
	position: relative;
	z-index: 1;

	@include bp-below("small") {
		margin: 0 auto;
		width: calc(100% - #{$bsu * 2});
	}
}

.c-info-widget {
	@include flex-extend;
	align-items: stretch;

	box-shadow: 0 10px 20px 5px rgba($black, 0.2);
}

.c-info-widget__weather {
	@include flex-extend;
	align-items: center;
	justify-content: center;

	background: $white;
	border-radius: 5px 0 0 5px;
}



// Two interacting layout variants:
// column / row
// right / centre

@mixin info-widget-column {
	.c-info-widget {
		flex-direction: column;
	}
	.c-info-widget__weather {
		border-radius: 5px 5px 0 0;
	}
	.c-info-widget__primary {
		min-width: $weather-width-single;
	}
	.c-info-widget__body {
		border-radius: 0 0 5px 5px;
	}
}

@mixin info-widget-centre {
	.c-info-widget__wrapper {
		@include flex-extend;
		justify-content: center;
		align-items: center;
	}
	.c-info-widget__weather:last-child {
		border-radius: 5px;
	}
}

@mixin info-widget-right {
	.c-info-widget__wrapper {
		position: absolute;
		right: 0;
	}
	.c-info-widget__weather:last-child {
		border-radius: 5px 0 0 5px;
	}
}

@include bp-below(700px) {
	@include info-widget-column;
	.c-info-widget__head {
		border-radius: 0;
	}
	.c-info-widget__body {
		border-radius: 0 0 5px 5px;
	}
}
@include bp-below("medium") {
	@include info-widget-centre;
	.c-info-widget__head {
		border-radius: 0 5px 0 0;
	}
	.c-info-widget__body {
		border-radius: 0 0 5px 0;
	}
}
@include bp("medium") {
	@include bp-below("large") {
		@include info-widget-right;
	}
}
@include bp("large") {
	@include info-widget-right;
	@include bp-below("extra-large") {
		@include info-widget-column;
		.c-info-widget__weather {
			border-radius: 5px 0 0 0;
		}
		.c-info-widget__body {
			border-radius: 0 0 0 5px;
		}
	}
}

.c-info-widget__primary {
	@include flex-extend;
	flex-direction: column;

	max-width: 330px;
	background: var(--primary-a);
}

.c-info-widget__head {
	padding: $bsu;
}

.c-info-widget__head-title {
	display: block;
	margin-bottom: $ssu;

	@include font-heading-18;
}

.c-info-widget__head-text {
	@include font-base-12;
}

.c-info-widget__body {
	flex-grow: 1;
	padding: $bsu;
}

.c-info-widget__body-text {
	@include font-base-14;
	white-space: pre-wrap;
}

.c-info-widget__body-link {
	@include font-heading-14;

	&::after {
		@include font-icon;
		content: $iconf-arrow-right;

		margin-left: $bsu;
	}
}
