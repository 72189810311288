// OBJECT.CONTROL

.o-control {
	margin: $lsu 0;
	@include bp("medium") {
		margin: 120px 0;
	}
}

.o-layout-header + .o-control {
	@include bp("medium") {
		margin-top: $lsu;
	}
}

.o-layout-main > .o-control:first-child {
	margin-top: 0;
}

.o-control__heading {
	display: block;
	margin-bottom: $lsu;

	text-align: center;

	@include font-heading-28;
	@include bp('large') {
		@include font-heading-36;
	}

	@supports (border-bottom: var(--primary-a)) {
		position: relative;
		padding-bottom: $bsu;
		&::after {
			content: "";
			position: absolute;
			right: 50%;
			bottom: 0;
			transform: translateX(50%);

			width: 80px;
			border-bottom: 4px solid var(--primary-a);
		}
	}
}

.o-layout-aside .o-control__heading {
	@include font-heading-20;
	margin-bottom: 0.5em;

	@include bp('large') {
		@include font-heading-24;
	}
}

.o-control__figcaption {
	@include ui-border('bottom');
	@include font-body-text;
	padding: $bsu 0;
}

.o-layout-aside .o-control:first-child {
	margin-top: 0;
}